import React from "react";
import Chart from "react-apexcharts";

const PieChart = ({ series, labels }) => {

  // Define colors based on the series values
  const getColor = (value) => {
    if (value === 100) return "#35C38F"; // Green for Yes
    if (value === 90) return "#F46A6A"; // Red for No
    return "#556EE6"; // Default color
  };

  // Ensure series and labels are defined
  const dataSeries = series || [100, 90]; // Default series data
  const dataLabels = labels || ["Yes", "No"]; // Default labels

  // Generate color array based on series
  const colors = dataSeries.map(getColor);

  const options = {
    series: dataSeries,
    chart: {
      type: "pie",
    },
    colors: colors,
    labels: dataLabels,
    legend: {
      position: "bottom",
    },
    tooltip: {
      y: {
        formatter: (value) => "" // Tooltip should not show value
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div id="chart">
      <Chart
        options={options}
        series={dataSeries}
        type="pie"
        height={380}
      />
    </div>
  );
};

export default PieChart;

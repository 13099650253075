
const USER_ROLE = Object.freeze({
  Company: "company",
  Employee: "employee",
});

const USER_DEPARTMENT = Object.freeze({
  Admin: "company",
  Manager: "manager",
  User: "user",
});

const LOG_ACTION = Object.freeze({
  Created: "created",
  Updated: "updated",
  Approved: "approved",
  Deleted: "deleted",
  Login: "login",
  Logout: "logout",
});

const STATIC_GROUPS = {
  water: {
    A: "Water withdrawal by source (GRI 303-3, BRSR-P6)",
    B: "Total Water Discharge (GRI 303-4, BRSR-P6)",
    C: "Total volume of water consumption (GRI 303-5, BRSR-P6)",
    D: "Finished Goods Quantity Produced",
    E: "Water intensity   (BRSR-P6)",
    F: "Water Recycle/Reuse",
    G: "Rain Water",
    H: "Water Withdrawal from water stress area",
    I: "Water Sources",
  },
  waste: {
    A: "Type of Waste Generated [GRI 305-6, GRI 305-7,GRI 306-1,2, GRI 306-3, BRSR P8]",
    B: "Waste Diverted from Disposal [GRI 306-4,GRI 306-5, BRSR P8]",
    B1: "Hazardous Waste",
    B2: "Non - Hazardous Waste",
    B3: "E-waste",
    C: "Waste directed to Disposal [GRI 306-4,GRI 306-5, BRSR P8]",
    C1: "Hazardous Waste",
    C2: "Non - Hazardous",
    D: "Hazardous Waste Management",
    E: " Non-Hazardous Waste Management",
    F: "Total expenses Paid to external Agency Towards waste handling, treatment &, disposed.",
    // G: "Revenue Earned due to waste Recovery , Recycle &,Reuse.",
  },
  energy: {
    // A: "Energy consumption within the Organization  GRI 302-1",
    // A1: "Energy consumption",
    // A2: "Energy Sold",
    // B: "Energy consumption outside of the Organization GRI 302-2",
    // B1: "Upstream categories",
    // B2: "Downstream categories",
    C: "Total energy consumption & Intensity",
    C1: "Total energy from renewable sources",
    C2: "Total energy from non- renewable sources",
    D: "Reduction of energy consumption",
    D1: "Types of energy included in the reductions",
  },
  biodiversity: {
    A: "Biodiversity value of water bodies and habitat affected by Company's discharges",
    B: "4 IUCN Red List species and national conservation list species with habitats in areas affected by operations GRI 304-4",
    B1: "Total number of IUCN Red List species and national conservation list species with habitats in areas affected by the operations of the organization, by level of extinction risk:",
    C: "Plantation",
  },

  aqi: {
    A: "Direct (Scope 1) GHG emissions GRI 302-4 and 305-5,GRI 305-1,305-4",
    A1: "Scope 1 Emission",
    B: "Energy indirect (Scope 2) GHG Emission GRI 305-2 and 305-4, 53GRI 305-3",
    B1: "Scope 2 Emission",
    C: "Other indirect (Scope 3) GHG emission GRI-305-3",
    C1: "Upstream",
    C2: "DownStream",
    D: "Total Emission GRI 305-4",
    E: "Reduction of GHG Emission  in Absolute Quantity GRI 305-5",
    F: "Emission of Ozone Deplition Substance GRI 305-6",
    F1: " Production ofODS (Ozone Depleting Substance)",
    G: " Nitrogen oxides (NOx), sulphur oxides (SOx), and other significant air emissions GRI 305-7",
    H: "Calculation Approach",
    I: "Break down of Emission",
  },
  basic: {
    A: "Climate strategy & policy",
    B: "Emission Measurement and Reporting",
    C: "Carbon reduction Initiatives",
    D: "Climate Change Adaptation and Resilience",
    E: "For each operational site owned, leased, managed in, or adjacent to, protected areas and areas of high biodiversity value outside protected areas, the following information",
    F: "Nature of significant direct and indirect impacts on biodiversity with reference to one or more of the following",
    G: "Significant direct and indirect positive and negative impacts with reference to the following",
    H: "Habitats protected or restored",
  },
};

const MONTHS_ARRAY_FULLNAME = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const FREQUENCY_CONSTANTS = {
  frequencyType: "",
  frequencyMonth: "",
  frequencyYear: "",
  frequencyDate: "",
};

const FORM_FIELDS = {
  prevValue: 0,
  target: 0,
  comment: "",
  value: "",
  attachment: "",
  unit: "",
};

const FINANCIALS_LABELS = {
  water: {
    A: "Total water withdrawal (Permissible limit as per regulatory norms)",
    B: "Total water discharge (Permissible limit as per regulatory norms)",
    C: "Total water consumption (Permissible limit as per regulatory norms)",
  },
  waste: {
    A: "Total Waste Generated (Permissible limit as per regulatory norms)",
    D: "Total Hazardous Waste (Permissible limit as per regulatory norms)",
    E: "Total Non-Hazardous Waste (Permissible limit as per regulatory norms)",
  },
  energy: {
    A: "Total Energy consumption from non-renewable sources (Permissible Limit as per Regulatory Norm)",
    B: "Total (Permissible limit as per regulatory norms)",
    C: "Total (Permissible limit as per regulatory norms)",
  },
};

const PERMISSBLE_LIMIT_GUIDANCE = `Absolute Value To Be Taken From Environmental  Compliance (State Pollution Control Board) CTO Document.
  NOTE - 1. If no filter is selected, the default value displayed will be 0.
         2. For the monthly filter, the permissible value from the Absolute data is divided by 12 (current year data by default, adjusts with year change).
         3. For the daily filter, the permissible value from the Absolute data is divided by 365 (366 in leap years).`;

const ENERGY_UNITS_1 = [
  "Gega joule",
  "Mega joule",
  "Joules",
  "Kilo watt hours",
];

const ENERGY_UNITS_2 = ["Unit of Energy Intensity"];

const ENERGY_UNITS_3 = ["Estimated", "Modeled", "Direct Measurement"];

const ENERGY_UNITS_4 = ["Yes", "No"];
const WASTE_UNITS_1 = ["Metric tonn"];
const WATER_UNITS_1 = ["Kilo Liter", "Mega liter", "Meter cube"];
const WATER_UNITS_2 = ["(m3/revenue in ₹Mn)"];
const WATER_INTENSITY = [
  "Unit Of Water Intensity in rupees",
  "Unit Of Water Intensity in dollar",
  "Unit Of Water Intensity in euro",
];
const PERFORMANCE_HEADING = ["Highlight", "Achievements", "Target"];

const CHARTS_LABEL = {
  waste: {
    A: "Total Waste Generated",
    D: "Hazardous Waste Management",
    E: " Non-Hazardous Waste Management",
  },
  energy: {
    A1: "Scope 1 Emission",
    B1: "Scope 2 Emission",
    C: "Scope 3 Emission",
  },
};

const TARGET_LABEL = {
  water: {
    A: "Total water withdrawal",
    B: "Total water discharge",
    C: "Total water consumption",
  },
  waste: {
    A: "Total Waste Generated",
    D: "Total Hazardous Waste",
    E: "Total Non-Hazardous Waste",
  },
};

const GOVERNANCE_STATIC_GROUPS = {
  EconomicPerformance: {
    A: "Direct economic value generated and distributed GRI 201-1",
    A1: "Direct economic value generated and distributed (EVG&D) on an accruals basis,including the basic components for the organization’s global operations as listed below.If data are presented on a cash basis, report the justification for this decision in additionto reporting the following basic components:",
    A2: "Economic Value Generated & Distributed  reported  at",
    B: "Financial implications and other risks and opportunities due to climate change GRI-202-2",
    C: "Defined benefit plan obligations and other retirement plans GRI 202-3",
    C1: "",
    C2: "The benefits plan  liabilities are met by the organization’s through",
    C3: "Percentage of salary contributed towards benefit plan by",
    D: "Financial assistance received from government GRI 201-4",
    D1: "Total monetary value of financial assistance received by the organization from any government during the reporting period, including:",
    D2: "Economic Value Generated & Distributed  reported  at ",
  },
  MarketPerformance: {
    A: "Ratios of standard entry level wage by gender compared to local minimum wage GRI 202-1",
    B: "Proportion of senior management hired from the local community GRI 202-2",
  },
  IndirectEconomicEmpats: {
    A: "Infrastructure investments and services supported GRI 203-1",
    B: "Significant indirect economic impacts GRI 203-2",
  },
  ProportionOfSpendingLocalSuppliers: {
    A: "Proportion of spending on local suppliers GRI 204-1",
  },
  AntiCorruption: {
    A: "Operations assessed for risks related to corruption GRI 205-1",
    B: "Communication and training about anti-corruption policies and procedures GRI 205-2",
    C: "Confirmed incidents of corruption and actions taken GRI 205-3",
  },
  AntiCompetitiveBehaviour: {
    A: "Legal actions for anti-competitive behavior, anti-trust, and monopoly practices 206-1",
  },
  Tax: {
    A: "Approach to tax  GRI 207-1",
    A1: "A description of the approach to tax, including:",
    B: "Tax governance, control, and risk management GRI 207-2",
    B1: "A description of the tax governance and control framework, including:",
    C: "Stakeholder engagement and management of concerns related to tax GRI 207-3",
    C1: "A description of the approach to stakeholder engagement and management of stakeholder concerns related to tax, including:",
    D: "Country-by-country reporting GRI 207-4",
    D1: "For each tax jurisdiction reported in Disclosure 207-4-a:",
  },
  GovernanceParameters: {
    A: "Board Diversity ",
    A1: "Measure the Diversity of The Board in terms of",
    B: "Executive Compensation",
    C: "Shareholder Rights",
    D: "Ethics and Compliance",
    E: "Risk Management",
    F: "Transparency and Reporting",
    G: "Regulatory Compliance",
    H: "Leadership and Strategic Direction",
    I: "Stakeholder Engagement",
    J: "Sustainability and Long-term Value",
  },
  GovernanceKpis: {
    A: "Board Composition and Diversity",
    B: "Audit and Risk Management",
    C: "Executive Compensation",
    D: "Ethics and Compliance",
    E: "Shareholder Rights",
    F: "Transparency and Disclosure",
    G: "Stakeholder Engagement",
    H: "Governance Policies and Practices",
    I: "Cybersecurity and Data Privacy",
    J: "Regulatory Compliance",
  },
};

const GROUPS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
];
const GOVERNANCE_KPI_DASHBOARD = [
  "A",
  "C",
  "G",
  "J",
  "I",
  "B",
  "D",
  "H",
  "E",
  "F",
];
const GOVERNANCE_KPI_GROUPS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
];
const SMALL_GROUPS = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
];

const ECONOMIC_PERFORMANCE_DROPDOWN_VALUES = {
  "Medical insurance": [{ value: "Yes" }, { value: "No" }],
  "Provident Fund": [{ value: "Yes" }, { value: "No" }],
  Gratuity: [{ value: "Yes" }, { value: "No" }],
  "Retirement plan": [{ value: "Yes" }, { value: "No" }],
  "Pension Plan": [{ value: "Yes" }, { value: "No" }],
  "Employees State Insurance": [{ value: "Yes" }, { value: "No" }],
  "Economic Value Generated & Distributed  reported  at": [
    { value: "Regional" },
    { value: "Country" },
    { value: "Global" },
    { value: "Market Level" },
  ],
};

const GOVERNANCE_KPIS_DROPDOWN_CONSTANTS = {
  "Existence and effectiveness of a risk management framework.": [
    { value: "Yes" },
    { value: "No" },
  ],
  "Existence of a whistleblower protection policy.": [
    { value: "Yes" },
    { value: "No" },
  ],
  "Voting results of key shareholder resolutions.": [
    { value: "Yes" },
    { value: "No" },
  ],
  "Timeliness and accuracy of financial and non-financial reporting.": [
    { value: "Yes" },
    { value: "No" },
  ],
  "Compliance with regulatory reporting requirements.": [
    { value: "Yes" },
    { value: "No" },
  ],
  "Frequency of ESG-related disclosures and updates.": [
    { value: "Yes" },
    { value: "No" },
  ],
  "Frequency of shareholder engagement meetings.": [
    { value: "Yes" },
    { value: "No" },
  ],
  "Existence of a documented corporate governance framework.": [
    { value: "Yes" },
    { value: "No" },
  ],
  "Compliance with international governance standards and best practices.": [
    { value: "Yes" },
    { value: "No" },
  ],
  "Existence of data privacy and cybersecurity policies.:": [
    { value: "Yes" },
    { value: "No" },
  ],
};

const GOVERNANCE_ROUTE_CONSTANTS = [
  {
    dropDownValues: ECONOMIC_PERFORMANCE_DROPDOWN_VALUES,
    routeSubPath: "economic_performance",
    groupConstant: "EconomicPerformance",
    label: "Economic Performance",
  },
  {
    dropDownValues: [],
    routeSubPath: "market_performance",
    groupConstant: "MarketPerformance",
    label: "Market Performance",
  },
  {
    dropDownValues: [],
    routeSubPath: "indirect_economic_impacts",
    groupConstant: "IndirectEconomicEmpats",
    label: "Indirect Economic Impacts",
  },
  {
    dropDownValues: [],
    routeSubPath: "proportion_of_spending_on_local_suppliers",
    groupConstant: "ProportionOfSpendingLocalSuppliers",
    label: "Proportion of spending on local suppliers",
  },
  {
    dropDownValues: [],
    routeSubPath: "anti-corruption",
    groupConstant: "AntiCorruption",
    label: "Anti-corruption ",
  },
  {
    dropDownValues: [],
    routeSubPath: "anti-competitive_behavior",
    groupConstant: "AntiCompetitiveBehaviour",
    label: "Anti-competitive Behavior",
  },
  {
    label: "Tax",
    routeSubPath: "tax",
    groupConstant: "Tax",
    dropDownValues: [],
  },
  {
    dropDownValues: [],
    routeSubPath: "governance_parameters",
    groupConstant: "GovernanceParameters",
    label: "Governance Parameters (BRSR)",
  },
];
const REVENUE_LABELS = {
  water: {
    A: "Water intensity (Revenue)",
  },
  energy: {
    A: "Intensity of Energy Consumption Within the Organisation/Revenue",
    B: "Intensity of Energy Consumption outside of the organisation/Revenue",
    C: "Intensity of Total Energy Consumption/Revenue",
  },
  emission: {
    A: "Scope-1 Emission Intensity/Revenue",
    B: "Scope-2 Emission Intensity/Revenue",
    C: "Scope-3 Emission Intensity/Revenue",
    D: "GHG Emission Intensity/Revenue",
  },
};
const REVENUE_HEADINGS = {
  water: {
    A: "Total Water consumption",
  },
  energy: {
    A: "Total Energy Consumption Within the Organisation",
    B: "Total Energy Consumption outside of the organisation",
    C: "Total Energy Consumption",
  },
  aqi: {
    A: "Total Scope-1 Emission Intensity",
    B: "Total Scope-2 Emission Intensity",
    C: "Total Scope-3 Emission Intensity",
    D: "Total GHG Emission Intensity",
  },
};

const SOCIAL_GROUP_CONSTANTS = {
  Employment: {
    A: "New employee hires and employee turnover",
    A_A: "Total number and rate of new employee hires during the reporting period, by age group,gender and region.",
    A_A_A: "Male",
    A_A_B: "Female",
    A_A_C: "Employees from Local State",
    A_A_D: "Employees from Other State",
    A_B_A: "Male",
    A_B_B: "Female",
    A_B_C: "Employees from Local State",
    A_B_D: "Employees from Other State",
    A_B: "Total number and rate of employee turnover during the reporting period, by age group, gender and region",
    B: "Benefits provided to full-time employees that are not provided to temporary or part time employees",
    B_A: "Benefits which are standard for full-time employees of the organization but are not provided to temporary or part-time employees, by significant locations of operation.These include, as a minimum",
    C: "Parental leave",
    C_A: "Total number of employees that were entitled to parental leave, by gender.",
    C_B: "Total number of employees that took parental leave, by gender",
    C_C: "Total number of employees that returned to work in the reporting period after parental leave ended, by gender",
    C_D: "Total number of employees that returned to work after parental leave ended that were still employed 12 months after their return to work, by gender",
    C_E: "Return to work and retention rates of employees that took parental leave, by gender.",
    C_E_A: "Return to work, by gender.",
    C_E_B: "Retention rates, by gender.",
  },
  Ohc: {
    A: "Occupational health and safety management system",
    A_A: "A statement of whether an occupational health and safety management system has been implemented.",
    B: "Hazard identification, risk assessment, and incident investigation",
    B_A: "A description of the processes used to identify work-related hazards and assess risks on a routine and non-routine basis, and to apply the hierarchy of controls in order to eliminate hazards and minimize risks, including:",
    C: "Occupational health services",
    D: "Worker participation, consultation, and communication on occupational health and safety",
    E: "Worker training on occupational health and safety",
    F: "Promotion of worker health",
    G: "Prevention and mitigation of occupational health and safety impacts directly linked by business relationships",
    H: "Workers covered by an occupational health and safety management system",
    H_A: "If the organization has implemented an occupational health and safety management system based on legal requirements and/or recognized standards/guidelines",
    I: "Work Related Injuries.",
    I_A: "For all employees:",
    I_B: "For all workers who are not employees but whose work and/or workplace is controlled by the organization:",
    I_C: "The work-related hazards that pose a risk of high-consequence injury, including",
    J: "Work-related ill health",
    J_A: "For all employees:",
    J_B: "For all workers who are not employees but whose work and/or workplace is controlled by the organization:",
    J_C: "The work-related hazards that pose a risk of ill health, including:",
  },
  TrainingAndEducation: {
    A: "Average hours of training per year per employee",
    A_A: "Average hours of training that the organization’s employees have undertaken during the reporting period, by:",
    A_A_A: "Gender",
    B: "Programs for upgrading employee skills and transition assistance programs",
    C: "Percentage of employees receiving regular performance and career development reviews",
  },
  DiversityAndEqualOpportunity: {
    A: "Diversity of governance bodies and employees",
    A_A: "Percentage of individuals within the organization’s governance bodies in each of the following diversity categories:",
    A_A_A: "Male",
    A_A_B: "Female",
    A_B: "Percentage of employees per employee category in each of the following diversity categories",
    A_B_A: "Male",
    A_B_B: "Female",
    B: "Ratio of basic salary and remuneration of women to men",
  },
  NonDiscrimination: {
    A: "Incidents of discrimination and corrective actions taken",
    A_A: "Status of the incidents and actions taken with reference to the following:",
  },
  FreedomOfAssociationAndCollectiveBargaining: {
    A: "Operations and suppliers in which the right to freedom of association and collective bargaining may be at risk",
    A_A: "Operations and suppliers in which workers’ rights to exercise freedom of associationor collective bargaining may be violated or at significant risk either in terms of",
  },
  ChildLabour: {
    A: "Operations and suppliers at significant risk for incidents of child labor",
  },
  ForcedOrCompulsoryLabor: {
    A: "Operations and suppliers at significant risk for incidents of forced or compulsory labor",
  },
  SecurityPractices: {
    A: "Security personnel trained in human rights policies or procedures",
  },
  RightsOfIndigenousPeoples: {
    A: " Incidents of violations involving rights of Indigenous peoples",
    A_A: "Status of the incidents and actions taken with reference to the following:",
  },
  LocalCommunities: {
    A: "Operations with local community engagement, impact assessments, and development programs",
    A_A: "Percentage of operations with implemented local community engagement, impact assessments, and/or development programs, including the use of.",
    B: "Operations with significant actual and potential negative impacts on local communities",
  },
  SupplierSocialAssessment: {
    A: "New suppliers that were screened using social criteria",
    B: "Negative social impacts in the supply chain and actions taken",
  },
  PublicPolicy: { A: "Political contributions" },
  CustomerHealthAndSafety: {
    A: "Assessment of the health and safety impacts of product and service categories",
    B: "Incidents of non-compliance concerning the health and safety impacts of products and services",
    B_A: "Total number of incidents of non-compliance with regulations and/or voluntary codes concerning the health and safety impacts of products and services within the reporting period, by",
  },
  MarketingAndLabeling: {
    A: " Requirements for product and service information and labeling",
    A_A: "Whether each of the following types of information is required by the organization’s procedures for product and service information and labeling",
    B: " Incidents of non-compliance concerning product and service information and labeling",
    B_A: "Total number of incidents of non-compliance with regulations and/or voluntary codes concerning product and service information and labeling, by:",
    C: "Incidents of non-compliance concerning marketing communications",
    C_A: "Total number of incidents of non-compliance with regulations and/or voluntary codes concerning marketing communications, including advertising, promotion, and sponsorship, by",
  },
  CustomerPrivacy: {
    A: "Substantiated complaints concerning breaches of customer privacy and losses of customer data",
    A_A: "Total number of substantiated complaints received concerning breaches of customer privacy, categorized by",
  },
};

const SOCIAL_ROUTE_CONSTANT = [
  {
    groupConstant: "Employment",
    label: "Employment",
    routeSubPath: "employment",
  },
  {
    groupConstant: "Ohc",
    label: "OH&C",
    routeSubPath: "ohc",
  },
  {
    groupConstant: "TrainingAndEducation",
    label: "Training And Education",
    routeSubPath: "training_and_education",
  },
  {
    groupConstant: "DiversityAndEqualOpportunity",
    label: "Diversity And Equal Opportunity",
    routeSubPath: "diversity_and_equal_opportunity",
  },
  {
    groupConstant: "NonDiscrimination",
    label: "Non-Discrimination",
    routeSubPath: "non_discrimination",
  },
  {
    groupConstant: "FreedomOfAssociationAndCollectiveBargaining",
    label: "Freedom of Association and Collective Bargaining",
    routeSubPath: "freedom_of_association_and_collective_bargaining",
  },

  {
    groupConstant: "ChildLabour",
    label: "Child Labor",
    routeSubPath: "child_labor",
  },
  {
    groupConstant: "ForcedOrCompulsoryLabor",
    label: "Forced or Compulsory Labor",
    routeSubPath: "forced_or_compulsory_labor",
  },
  {
    groupConstant: "SecurityPractices",
    label: "Security Practices",
    routeSubPath: "security_practices",
  },
  {
    groupConstant: "RightsOfIndigenousPeoples",
    label: "Rights of Indigenous Peoples",
    routeSubPath: "rights_of_indigenous_peoples",
  },
  {
    groupConstant: "LocalCommunities",
    label: "Local Communities",
    routeSubPath: "local_communities",
  },
  {
    groupConstant: "SupplierSocialAssessment",
    label: "Supplier Social Assessment",
    routeSubPath: "supplier_social_assessment",
  },
  {
    groupConstant: "PublicPolicy",
    label: "Public Policy",
    routeSubPath: "public_policy",
  },
  {
    groupConstant: "CustomerHealthAndSafety",
    label: "Customer Health and Safety",
    routeSubPath: "customer_health_and_safety",
  },
  {
    groupConstant: "MarketingAndLabeling",
    label: "Marketing and Labeling",
    routeSubPath: "marketing_and_labeling",
  },
  {
    groupConstant: "CustomerPrivacy",
    label: "Customer Privacy",
    routeSubPath: "customer_privacy",
  },
];

const REVENUE_FIELDS = [
  // {
  //   type: "water",
  //   label: "Total Water consumption",
  //   key: "waterA",
  // },
  {
    type: "water",
    label: "Water intensity (Revenue)",
    key: "",
    valKey: "waterA",
  },
  // {
  //   label: "Total Energy Consumption Within the Organisation",
  //   key: "energyA",
  //   type: "energy",
  // },
  {
    type: "energy",
    label: "Intensity of Energy Consumption Within the Organisation/Revenue",
    key: "",
    valKey: "energyA",
  },
  // {
  //   label: "Total Energy Consumption outside of the organisation",
  //   key: "energyB",
  //   type: "energy",
  // },
  {
    label:
      "Intensity of Energy Consumption outside of the organisation/Revenue",
    key: "",
    type: "energy",
    valKey: "energyB",
  },
  // { label: "Total Energy Consumption", key: "energyC", type: "energy" },
  {
    label: "Intensity of Total Energy Consumption/Revenue",
    key: "",
    type: "energy",
    valKey: "energyC",
    guidance:
      "Total energy from renewable sources + Total energy from non-renewable sources.",
  },
  // { label: "Total Scope-1 Emission Intensity", key: "aqiA", type: "emission" },
  {
    label: "Scope-1 Emission Intensity/Revenue",
    key: "",
    type: "emission",
    valKey: "aqiA",
  },
  // { label: "Total Scope-2 Emission Intensity", key: "aqiB", type: "emission" },
  {
    label: "Scope-2 Emission Intensity/Revenue",
    key: "",
    type: "emission",
    valKey: "aqiB",
  },
  // { label: "Total Scope-3 Emission Intensity", key: "aqiC", type: "emission" },
  {
    label: "Scope-3 Emission Intensity/Revenue",
    key: "",
    type: "emission",
    valKey: "aqiC",
  },
  // { label: "Total GHG Emission Intensity", key: "aqiD", type: "emission" },
  {
    label: "GHG Emission Intensity/Revenue",
    key: "",
    type: "emission",
    valKey: "aqiD",
  },
];

const NUMERIC_FIELDS = [
  "Under 30 Years",
  "30-50 years old",
  "Over 50 Year Old",
  "Male",
  "Female",
  "Incident reviewed by the organization.",
  "Remediation plans being implemented.",
  "Remediation plans that have been implemented, with results reviewed through routine internal management review processes.",
  "Incident no longer subject to action.",
  "The number and rate of fatalities as a result of work-related injury.",
  "The number and rate of high-consequence work-related injuries (excluding fatalities)",
  "The number and rate of recordable work-related injuries",
  "The main types of work-related injury",
  "The number of hours worked.",
  "The number and rate of fatalities as a result of work-related injury",
  "The number and rate of high-consequence work-related injuries (excluding fatalities)",
  "The number and rate of recordable work-related injuries",
  "The main types of work-related injury",
  "The number of hours worked.",
  "Incidents of non-compliance with voluntary codes.",
  "Incidents of non-compliance with regulations resulting in a fine or penalty",
  "Incidents of non-compliance with regulations resulting in a warning;",
  "Incidents of non-compliance with regulations resulting in a fine or penalty",
  "Incidents of non-compliance with regulations resulting in a warning",
  "Incidents of non-compliance with voluntary codes",
  "Incidents of non-compliance with regulations resulting in a fine or penalty;",
  "Incidents of non-compliance with regulations resulting in a warning;",
  "Incidents of non-compliance with voluntary codes.",
  "Complaints received from outside parties and substantiated by the organization",
  "Complaints from regulatory bodies",
  "the number and percentage of all employees and workers who are not employees but whose work and/or workplace is controlled by the organization, who are covered by such a system;",
  "the number and percentage of all employees and workers who are not employees but whose work and/or workplace is controlled by the organization, who are covered by such a system that has been internally audited;",
  "the number and percentage of all employees and workers who are not employees but whose work and/or workplace is controlled by the organization, who are covered by such a system that has been audited or certified by an external party.",
  "Whether and, if so, why any workers have been excluded from this disclosure, including the types of worker excluded. ",
  "The number and rate of fatalities as a result of work-related injury.",
  "The number and rate of high-consequence work-related injuries (excluding fatalities)",
  "The number and rate of recordable work-related injuries",
  "The number of hours worked.",
  "The number of cases of recordable work-related ill health;",
  "The number of fatalities as a result of work-related ill health;",
  "Number of Employees whose Work/Workplace is controlled by the Organisation.",
  "Number of Employees Covered by the OHS System and internally audited.",
  "Number of Employees covered by the OHS System, Audited or Certified by an External party.",
  "Number of Employees Covered by the OHS System",
  "Number of Workers Excluded.",
  "Total number of incidents of discrimination during the reporting period.",
  "Percentage of significant product and service categories for which health and safety impacts are assessed for improvement",
  "Total number of identified leaks, thefts, or losses of customer data",
  "Percentage of new suppliers that were screened using social criteria.",
  "Number of suppliers assessed for social impacts.",
  "Number of suppliers identified as having significant actual and potential negative social impacts",
  "Percentage of suppliers terminated due to Social impact findings.",
  "% of Supplier improvement rate.",
];

const GROUPS_WITH_TOTAL = {
  Employment: ["C_A", "C_B", "C_C", "C_D"],
  Ohc: ["I_A", "I_B"],
  TrainingAndEducation: [],
  DiversityAndEqualOpportunity: [],
  NonDiscrimination: ["A_A"],
  FreedomOfAssociationAndCollectiveBargaining: [],
  ChildLabour: [],
  ForcedOrCompulsoryLabor: [],
  SecurityPractices: [],
  RightsOfIndigenousPeoples: [],
  LocalCommunities: [],
  SupplierSocialAssessment: [],
  PublicPolicy: [],
  CustomerHealthAndSafety: ["B_A"],
  MarketingAndLabeling: ["B_A", "C_A"],
  CustomerPrivacy: ["A_A"],
};

const ApprovalEnums = {
  Approved: "approved",
  Pending: "pending",
  Rejected: "rejected",
};

const NON_EDITABLE_FIELDS = [
  ...NUMERIC_FIELDS,
  "Existence and effectiveness of a risk management framework.",
  "Existence of a whistleblower protection policy.",
  "Voting results of key shareholder resolutions.",
  "Timeliness and accuracy of financial and non-financial reporting.",
  "Compliance with regulatory reporting requirements.",
  "Frequency of ESG-related disclosures and updates.",
  "Frequency of shareholder engagement meetings.",
  "Existence of a documented corporate governance framework.",
  "Compliance with international governance standards and best practices.",
  "Existence of data privacy and cybersecurity policies.",
  "% of Economic Value Shared  Note: (ii/i)*100",
  "Percentage of water recycle/reuse: (Total water quantity recycled and reused /  Water withdrawal by source)*100",
  "( Total Water consumption / Finished Goods Quantity Produced )",
  "( Total Water consumption / Revenue )",
  "Total energy consumption within the organization",
  "Intensity of Energy Consumption Within the Organisation/Revenue",
  "Total Energy Consumption outside of the Organisation",
  "Intensity of Energy Consumption outside of the organisation/Revenue",
  "Total Energy Consumption",
  "Intensity of Energy Consumption Within the Organisation/Finished goods quantity produced",
  "Intensity of Energy Consumption outside of the organisation/Finished goods quantity produced",
  "Scope-1 Emission Intensity/Revenue",
  "Scope-2 Emission Intensity/Revenue",
  "Scope-3 Emission Intensity/Revenue",
  "Scope-3",
  "Scope-1",
  "Scope-2",
  "Scope-1 Emission Intensity/Finished goods quantity produced",
  "Scope-2 Emission Intensity/Finished goods quantity produced",
  "Scope-3 Emission Intensity/Finished goods quantity produced",
];

export {
  USER_ROLE,
  STATIC_GROUPS,
  MONTHS_ARRAY_FULLNAME,
  FREQUENCY_CONSTANTS,
  FORM_FIELDS,
  FINANCIALS_LABELS,
  PERMISSBLE_LIMIT_GUIDANCE,
  ENERGY_UNITS_1,
  ENERGY_UNITS_2,
  ENERGY_UNITS_3,
  ENERGY_UNITS_4,
  WASTE_UNITS_1,
  WATER_UNITS_1,
  WATER_UNITS_2,
  WATER_INTENSITY,
  PERFORMANCE_HEADING,
  CHARTS_LABEL,
  TARGET_LABEL,
  GOVERNANCE_STATIC_GROUPS,
  GROUPS,
  SMALL_GROUPS,
  ECONOMIC_PERFORMANCE_DROPDOWN_VALUES,
  GOVERNANCE_ROUTE_CONSTANTS,
  REVENUE_LABELS,
  REVENUE_HEADINGS,
  SOCIAL_GROUP_CONSTANTS,
  SOCIAL_ROUTE_CONSTANT,
  REVENUE_FIELDS,
  NUMERIC_FIELDS,
  GROUPS_WITH_TOTAL,
  GOVERNANCE_KPI_DASHBOARD,
  GOVERNANCE_KPI_GROUPS,
  ApprovalEnums,
  GOVERNANCE_KPIS_DROPDOWN_CONSTANTS,
  NON_EDITABLE_FIELDS,
  USER_DEPARTMENT,
  LOG_ACTION,
};

import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/authProvider";
import { useLocation } from "react-router-dom";
import SortableTable from "./assignFieldDraggableTable";

const AssignField = ({ setStep }) => {
  const { token, showAlert } = useAuth();
  const location = useLocation();
  const companyData = location.state;
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedKPI, setSelectedKPI] = useState("");
  const [fieldList, setFieldList] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (selectedCategory && selectedSubCategory && selectedKPI) {
      fetch(
        process.env.REACT_APP_BASE_URL +
          "assign-companies/assignedfields/" +
          companyData?.id +
          "/" +
          selectedCategory +
          "/" +
          selectedSubCategory +
          "/" +
          selectedKPI,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            const isTrue =
              result.data.filter(
                ({ status, isRequired }) => status && isRequired
              ).length === 0;
            setFieldList(
              result.data
                .map((e) => ({
                  ...e,
                  ...(isTrue ? { isRequired: true, status: true } : {}),
                }))
                .sort((a, b) => Number(a.position) - Number(b.position))
            );
            if (result.data.length > 0) {
              setUpdate(true);
            }
          }
        });
    } else {
      setFieldList([]);
    }
  }, [
    selectedCategory,
    selectedSubCategory,
    selectedKPI,
    token,
    companyData?.id,
  ]);

  const handleSubmit = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const data = {
      companyId: companyData?.id,
      catId: selectedCategory,
      subCatId: selectedSubCategory,
      kpiId: selectedKPI,
      fields: fieldList,
    };
    fetch(
      `${process.env.REACT_APP_BASE_URL}assign-companies/${
        update ? "updateassignfields" : "assignfields"
      }`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        // if (result.status) {
        //   setStep(2);
        // }
        showAlert(result.msg || result.message, result.status || false);
      });
  };
  const handleSubmitFields = () => {
    handleSubmit();
    setStep(2);
  };
  return (
    <div>
      <div className="mb-4">
        <AssignDropdownRow
          token={token}
          data={companyData}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedSubCategory={selectedSubCategory}
          setSelectedSubCategory={setSelectedSubCategory}
          selectedKPI={selectedKPI}
          setSelectedKPI={setSelectedKPI}
        />
      </div>

      <div className="table-responsive">
        <SortableTable
          tableClass="table align-middle table-bordered table-nowrap mb-0"
          data={fieldList}
          setData={setFieldList}
        />
      </div>
      <div className="row">
        <div className="col-md-12 text-end mt-3 ">
          <button
            className="btn btn-primary px-4 "
            onClick={handleSubmit}
          >
            Save
          </button>
          <button
            className="btn btn-primary px-4 mx-4"
            onClick={() => {
              handleSubmitFields();
            }}
          >
            Save & Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssignField;

const AssignDropdownRow = ({
  token,
  data,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  selectedKPI,
  setSelectedKPI,
}) => {
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubCategoryList] = useState([]);
  const [kpiList, setKpiList] = useState([]);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_BASE_URL + "assign-companies/category/" + data?.id,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status) {
          setCategoryList(result.data);
          const isselected = result.data.find((item) => item.status)?.catId;
          setSelectedCategory(isselected);
        }
      })
      .catch((error) => console.error(error));
  }, [data?.id, token, setSelectedCategory]);

  useEffect(() => {
    if (selectedCategory) {
      fetch(
        process.env.REACT_APP_BASE_URL +
          "assign-companies/assignedsubcategory/" +
          data?.id +
          "/" +
          selectedCategory,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            setSubCategoryList(result.data);
            const isselected = result.data.find(
              (item) => item.status
            )?.subCatId;
            setSelectedSubCategory(isselected);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setSubCategoryList([]);
    }
  }, [data?.id, token, selectedCategory, setSelectedSubCategory]);

  useEffect(() => {
    if (selectedCategory && selectedSubCategory) {
      fetch(
        process.env.REACT_APP_BASE_URL +
          "assign-companies/assignedkpi/" +
          data?.id +
          "/" +
          selectedCategory +
          "/" +
          selectedSubCategory,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status) {
            setKpiList(result.data);
            const isselected = result.data.find((item) => item.status)?.kpiId;
            setSelectedKPI(isselected);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setKpiList([]);
    }
  }, [data?.id, token, selectedCategory, selectedSubCategory, setSelectedKPI]);

  return (
    <>
      <div className="row mb-3 align-items-end">
        <div className="col-md-3">
          <div className="">
            <label className="form-label">Category</label>
            <select
              className="form-select"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {categoryList?.map((item, index) => (
                <option key={index} value={item.catId}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="">
            <label className="form-label">Sub Category</label>
            <select
              className="form-select"
              value={selectedSubCategory}
              onChange={(e) => setSelectedSubCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {subcategoryList?.map((item, index) => (
                <option key={index} value={item.subCatId}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="">
            <label className="form-label">KPI</label>
            <select
              className="form-select"
              value={selectedKPI}
              onChange={(e) => setSelectedKPI(e.target.value)}
            >
              <option value="">Select Kpi</option>
              {kpiList?.map((item, index) => (
                <option key={index} value={item.kpiId}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <button className="btn btn-primary px-4 btn-45">Search</button>
        </div>
      </div>
    </>
  );
};

import React from "react";
import Chart from "react-apexcharts";

const BarChart = ({ series, categories, unit }) => {
  const options = {
    series: series || [
      {
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
      },
    ],
    chart: {
      type: "bar",
      height: 150,
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetX: -20,
      offsetY: 0,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },

    colors: [
      "#88BE53",
      "#FCD163",
      "#EF8083",
      "#46A1E1",
      "#A889C4",
      "#FFA07A",
      "#90EE90",
      "#87CEEB",
      "#DDA0DD",
    ],

    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,

        barHeight: "90%",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    // dataLabels: {
    //   enabled: false,
    // },
    xaxis: {
      categories: categories || ["FY23", "FY22", "FY21", "FY20"],
    },
    unit: unit,
    tooltip: {
      y: {
        formatter: function (val) {
          return val + (unit || "KL");
        },
      },
    },
  };

  return (
    <div id="chart">
      <Chart
        options={options}
        series={options.series}
        type="bar"
        unit={unit}
        height={140}
      />
    </div>
  );
};

export default BarChart;

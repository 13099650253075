import ColumnChart from "../charts/columnChart";
import DonutChart from "../charts/donutChart";
import { CHARTS_LABEL, TARGET_LABEL } from "../../constants/constants";
import { useMemo, useState } from "react";
import useStore from "../../hooks/useStore";
import { observer } from "mobx-react";
import { Accordion } from "react-bootstrap";
import WasteAccordion from "./wasteAccordion";
import ShowPerformance from "../showPerformance";
import { toJS } from "mobx";
import { calculateMaxWithIncrease, getRoundedValue } from "../../utils/helpers";
import CustomSpinner from "../spinner/Spinner";

function DashboardWaste({ year }) {
  const {
    dashboardStore: { dashboardDetails },
    companyStore: { permissibleLimit, companyData },
  } = useStore();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const columnChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.waste;
    const result = ["A", "D", "E"].map((group) => {
      return {
        name: CHARTS_LABEL.waste[group],
        data: [year - 1, year]
          ?.map(
            (year) =>
              tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )[group]?.prevValue || 0
          )
          ?.map((e) => getRoundedValue(e)),
        target: [year - 1, year]
          ?.map(
            (year) =>
              tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )[group]?.target || 0
          )
          ?.map((e) => getRoundedValue(e)),
      };
    });
    return result;
  }, [dashboardDetails?.charts?.waste, year]);

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const doughNutChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.waste?.[year];
    const result = ["A", "D", "E"].map(
      (group) =>
        tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.[group]
          ?.prevValue || 0
    );
    const labels = ["A", "D", "E"].map((group) => CHARTS_LABEL.waste[group]);
    return { data: result || [], labels };
  }, [dashboardDetails?.charts?.waste, year]);

  const assignedKpi = toJS(companyData)?.assignKpi?.find(
    (e) => e.kpiIdAssignKpi.name === "Waste"
  );

  const limits = permissibleLimit?.filter(
    ({ kpiId, subCatId, catId, year: tempYear }) =>
      kpiId === assignedKpi?.kpiId &&
      catId === assignedKpi?.catId &&
      subCatId === assignedKpi?.subCatId &&
      year == tempYear
  );

  let maxLimitValue;

  if (limits && limits.length > 0) {
    maxLimitValue = Math.max(0, ...limits.map((ele) => ele.value));
  } else {
    const allValues = ["A", "D", "E"].flatMap((group) =>
      [year - 1, year].map(
        (year) =>
          dashboardDetails?.charts?.waste?.[year]?.reduce(
            (acc, curr) => ({ ...acc, ...curr }),
            {}
          )[group]?.prevValue || 0
      )
    );
    maxLimitValue = Math.max(0, ...allValues);
  }

  const maxValueWithIncrease = calculateMaxWithIncrease(
    { max: maxLimitValue },
    "max",
    "Inc"
  );
  return (
    <div className="row h-100">
      <div className="col-xl-12">
        <div className="card h-100 dashboard-card ">
          <div className="card-body">
            <div className="d-flex flex-wrap align-items-start">
              <h5 className="mb-3 text-muted"> Waste Report</h5>
              <div className="ms-auto">
                <div className="toolbar d-flex flex-wrap gap-2 ">
                  <div className="">
                    <p className="text-muted mb-1">Unit</p>
                    <h5 className="unit-value">
                      {dashboardDetails?.charts?.waste?.unit || "MT"}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            {dashboardDetails?.charts ? (
              <ColumnChart
                max={maxValueWithIncrease}
                data={
                  columnChartData?.map(({ data, name }) => ({
                    data: data.map((e) => getRoundedValue(e, 0)),
                    name,
                  })) || []
                }
                unit={dashboardDetails?.charts?.waste?.unit}
                categories={[
                  `FY${(year - 1).toString().slice(2)}`,
                  `FY${year.toString().slice(2)}`,
                ]}
                yaxis={
                  limits?.map((ele) => ({
                    y: getRoundedValue(ele.value) || 0, // Target value
                    borderColor: "#FF4560",
                    label: {
                      borderColor: "#FF4560",
                      style: {
                        color: "#fff",
                        background: "#FF4560",
                      },
                      text: `${TARGET_LABEL.waste[ele.group]}: ${ele.value} ${ele.unitIdMaster.short_name}`,
                    },
                  })) || []
                }
              />
            ) : (
              <div className="d-flex align-items-center justify-content-center h-75">
                <CustomSpinner />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="col-md-12 h-100 mt-4">
        {/* achievements and target  */}

        <div className="row">
          <div className="col-md-6 mb-4">
            <ShowPerformance label="waste" />
          </div>
          {/* donut chart  */}
          <div className="col-md-6 mb-4">
            <div className="card mb-0 h-100 dashboard-donut-card">
              <div className="card-body">
                <div className="d-flex flex-wrap align-items-start">
                  <h5 className="mb-3 text-muted donut-heading">
                    Waste Generated by Sources
                  </h5>
                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2 ">
                      <div className="">
                        <p className="text-muted mb-1">Unit</p>
                        <h6 className="unit-value">
                          {dashboardDetails?.charts?.waste?.unit}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                {dashboardDetails?.charts ? (
                  <div className="donut">
                    <DonutChart
                      data={doughNutChartData.data || []}
                      labels={doughNutChartData.labels || []}
                      unit={dashboardDetails?.charts?.waste?.unit || "MT"}
                    />
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-center h-75">
                    <CustomSpinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accordion
        className="dashboard-chart-accordion"
        onSelect={handleAccordionToggle}
      >
        <Accordion.Item key={`waste-accordian`} eventKey={0}>
          <Accordion.Header>
            {isAccordionOpen ? "See less" : "See more"}
          </Accordion.Header>
          <Accordion.Body>
            <WasteAccordion year={year} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default observer(DashboardWaste);

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import "./index.css";
import Layout from "../../../../utils/layout";
import { useAuth } from "../../../../context/authProvider";
import TabsComponent from "./employeeAssign";
import useStore from "../../../../hooks/useStore";
import { observer } from "mobx-react";
import useWindowSize from "../../../../hooks/useWindowSize";

const AddEmployeePage = () => {
  const location = useLocation();
  const emoloyeeData = location.state;
  const {
    employeeStore: { fetchEmployeeData },
  } = useStore();

  const companyData = location.state;

  const [step, setStep] = useState(0);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchEmployeeData({ signal, employeeId: emoloyeeData?.id });
    return () => {
      abortController.abort();
    };
  }, [emoloyeeData?.id, fetchEmployeeData]);

  const { width } = useWindowSize();

  const steps = [width < 992 ? "Basic" : "Basic Details", "Assign", "Unit"];
  return (
    <Layout>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-12 ">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">Add Employee</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/company/employee">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Add Employee</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="step-container">
              {steps.map((heading, index) => (
                <div
                  key={index}
                  className={`col step ${
                    index === step ? "active-step" : "inactive-step"
                  }`}
                  onClick={() => setStep(index)}
                  style={{ cursor: "pointer" }}
                >
                  <span className="number-wrapper">{index + 1}. </span>
                  {heading}
                </div>
              ))}
            </div>

            <div className="mt-4">
              {step === 0 && <Step1 setStep={setStep} data={companyData} />}

              {step === 1 && (
                <section>
                  <TabsComponent setStep={setStep} />
                </section>
              )}
              {step === 2 && <Step3 data={companyData} />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddEmployeePage;

export const Step1 = ({ setStep, data }) => {
  const { token, showAlert } = useAuth();
  const navigate = useNavigate();
  const [basicFields, setBasicFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    region: "",
    department: "",
    password: "",
    confirmPassword: "",
  });

  const [validated, setValidated] = useState(false);

  const handleBasicData = (e) => {
    const { name, value } = e.target;
    setBasicFields({
      ...basicFields,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const raw = JSON.stringify({
        firstName: basicFields?.firstName,
        lastName: basicFields?.lastName,
        email: basicFields?.email,
        phoneNumber: basicFields?.phoneNumber,
        region: basicFields?.region,
        department: basicFields?.department,
        password: basicFields?.password,
        address: basicFields?.address,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_BASE_URL}company/${
          data?.id ? "updateEmployee/" + data?.id : "createCompanyEmployee"
        }`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.status) {
            navigate("/company/employee/add-employee", { state: result.data });
            setStep(1);
          }
          showAlert(result.msg || result.message, result.status || false);
        })
        .catch((error) => console.error(error));
    }
    setValidated(true);
  };

  useEffect(() => {
    if (data) {
      setBasicFields(data);
    }
  }, [data]);

  return (
    <React.Fragment>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom01"
          >
            <Form.Label>First name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Your First Name"
              name="firstName"
              value={basicFields.firstName}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid first name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom02"
          >
            <Form.Label>Last name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Your Last Name"
              name="lastName"
              value={basicFields.lastName}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid last name.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom03"
          >
            <Form.Label>Phone</Form.Label>
            <Form.Control
              required
              type="number"
              placeholder="Enter Your Phone No."
              name="phoneNumber"
              value={basicFields.phoneNumber}
              onChange={handleBasicData}
              onWheel={(e) => e.target.blur()}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid phone number.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom04"
          >
            <Form.Label>Business Email</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Enter Your Email ID"
              name="email"
              value={basicFields.email}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom06"
          >
            <Form.Label>Region</Form.Label>
            <Form.Select
              required
              name="region"
              value={basicFields.region}
              onChange={handleBasicData}
            >
              <option value="">Select Region</option>
              <option value="India">India</option>
              <option value="United States">United States</option>
              <option value="Africa">Africa</option>
              <option value="Australia / New Zealand">
                Australia / New Zealand
              </option>
              <option value="Brazil">Brazil</option>
              <option value="Canada">Canada</option>
              <option value="Central America">Central America</option>
              <option value="China">China</option>
              <option value="Europe">Europe</option>
              <option value="Japan">Japan</option>
              <option value="Korea">Korea</option>
              <option value="Mexico">Mexico</option>
              <option value="Middle East">Middle East</option>
              <option value="South America">South America</option>
              <option value="South East Asia">South East Asia</option>
              <option value="Turkey">Turkey</option>
              <option value="UK">UK</option>
              <option value="Other">Other</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please provide a valid region.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom06"
          >
            <Form.Label>Password</Form.Label>
            <Form.Control
              required={data ? false : true}
              type="text"
              placeholder="Enter Password"
              name="password"
              value={basicFields.password}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid password.
            </Form.Control.Feedback>
            {basicFields.password !== basicFields.confirmPassword && (
              <p className="text-danger">
                Password And Confirm Password Should be Same.
              </p>
            )}
          </Form.Group>

          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom08"
          >
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              required={data ? false : true}
              type="text"
              placeholder="Enter Confirm Password"
              name="confirmPassword"
              value={basicFields.confirmPassword}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Confirm Password
            </Form.Control.Feedback>
            {basicFields.password !== basicFields.confirmPassword && (
              <p className="text-danger">
                Password And Confirm Password Should be Same.
              </p>
            )}
          </Form.Group>

          <Form.Group as={Col} className="mb-3" controlId="validationCustom07">
            <Form.Label>Address</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Your Address"
              name="address"
              value={basicFields.address}
              onChange={handleBasicData}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            as={Col}
            md="4"
            className="mb-3"
            controlId="validationCustom09"
          >
            <Form.Label>Department</Form.Label>
            <Form.Select
              required
              name="department"
              value={basicFields.department}
              onChange={handleBasicData}
            >
              <option value="">Select Department</option>
              <option value="Admin">Admin</option>
              <option value="Manager">Manager</option>
              <option value="User">User</option>
              
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Please selete a department.
            </Form.Control.Feedback>
          </Form.Group>

        </Row>
        <div className="text-center">
          <Button type="submit" className="px-4">
            Next
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};
export const Step3 = observer(({ data }) => {
  const navigate = useNavigate();
  const { showAlert } = useAuth();

  const {
    companyStore: { companyDataJson },
    employeeStore: { employeeDetails, assignUnit },
  } = useStore();

  const [newUnits, setNewUnits] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const body = JSON.stringify({
      empId: data?.id,
      fields: newUnits,
    });
    assignUnit({ body, navigate, showAlert });
  };

  useEffect(() => {
    // Initialize newUnits with existing units and their statuses
    setNewUnits(
      employeeDetails?.assignUnit?.map(({ name, address, pincode, status }) => ({
        name,
        address,
        pincode,
        status: status !== false, // Initialize status correctly
      })) || []
    );
  }, [employeeDetails?.assignUnit]);

  const handleCheckboxChange = (name, address, pincode, isChecked) => {
    setNewUnits((prev) => {
      const existingUnit = prev.find((unit) => unit.name === name);
      if (existingUnit) {
        // Update the status of the existing unit
        return prev.map((unit) =>
          unit.name === name
            ? { ...unit, status: isChecked }
            : unit
        );
      } else if (isChecked) {
        // Add new unit with status true if checked
        return [...prev, { name, address, pincode, status: true }];
      }
      return prev;
    });
  };

  return (
    <div className="col-md-4 w-100">
      <div className="table-responsive">
        <table className="table align-middle table-bordered table-nowrap mb-0">
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Unit</th>
              <th>Address</th>
              <th>Assign</th>
            </tr>
          </thead>
          <tbody>
            {companyDataJson?.assignUnit?.map(
              ({ name, address, pincode }, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{name}</td>
                  <td>{address}</td>
                  <td>
                    <div className="form-check font-size-20 p-0">
                      <input
                        className="form-check-input m-0 float-none"
                        type="checkbox"
                        checked={newUnits.some((unit) => unit.name === name && unit.status)}
                        id={index}
                        onChange={(event) => {
                          handleCheckboxChange(
                            name,
                            address,
                            pincode,
                            event.target.checked
                          );
                        }}
                      />
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-md-12 text-center mt-3">
          <button className="btn btn-primary px-4" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
});
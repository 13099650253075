import { Link, useLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";
import Layout from "../utils/layout";
import { STATIC_GROUPS } from "../constants/constants";
import useStore from "../hooks/useStore";
import { observer } from "mobx-react";
import info from "./info.svg";
import numToRoman from "../helpers/numToRoman";
import CustomTooltip from "../utils/tooltip/tooltip";
import { groupByConsecutiveGroups } from "../utils/helpers";
import { addGroupData } from "../helpers/groupDataCalculations";

function ShowDataRow({ data, id }) {
  const {
    companyStore: { units },
  } = useStore();
  return (
    <tr className="odd removeinput" key={id}>
      <td>{!id && id !== 0 ? "*" : numToRoman(id + 1)}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <CustomTooltip position="top" content={data?.guidance}>
              <img src={info} alt="" />
            </CustomTooltip>
          )}
        </span>
      </td>
      <td>
        <span>
          {
            units?.find((ele) => ele.id === (data?.unitId || data?.unit))
              ?.shortName
          }
        </span>
      </td>

      <td>
        <div>{data?.frequencyType || ""}</div>
        <div>{data?.frequencyDate || data?.frequencyMonth || ""}</div>
        <div>{data?.frequencyYear || ""}</div>
      </td>

      <td className="text-center">
        <span>{data.prevValue}</span>
      </td>
      <td className="text-center">
        <span>{data.target}</span>
      </td>
      <td>
        <span>{data.comment}</span>
      </td>
      <td>
        {data?.attachment ? <Link to={data.attachment}>Download</Link> : ""}
      </td>
    </tr>
  );
}

function RowTotal({ id, data, className }) {
  return (
    <tr className={`subTotalTr ${id}-footer ${className}`}>
      <td>
        <b>{""}</b>
      </td>
      <td
        colspan=""
        style={{
          fontWeight: "600",
        }}
      >
        Total
      </td>
      <td></td>
      <td></td>
      <td className="totalTd">{data?.prevValue}</td>
      <td className="totalTd">{data?.target}</td>
      <td></td>
      <td></td>
      {/* <td></td> */}
    </tr>
  );
}

function FormTableBody({ data, page }) {
  const values = useMemo(
    () =>
      (
        groupByConsecutiveGroups(
          data?.value?.sort((a, b) => Number(a.fieldId) - Number(b.fieldId))
        ) || []
      )?.map((value) => ({
        label: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].includes(
          value[0]?.group
        )
          ? ""
          : STATIC_GROUPS[page][value[0]?.group],
        value,
        group: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"].includes(
          value[0]?.group
        )
          ? ""
          : value[0]?.group,
      })),
    [data?.value, page]
  );
  let id = 0;
  return values?.map((ele, index) => (
    <>
      {!ele?.group ? null : (
        <tr className="table-sub-heading">
          <td>{numToRoman(id++ + 1)}</td>
          <td colSpan={7}>{ele.label}</td>
        </tr>
      )}
      {ele.value.map((e, i) => (
        <ShowDataRow data={e} id={!ele.label ? id++ : null} />
      ))}
      {ele?.value?.length !== 1 && (
        <RowTotal id={"asdfasacc"} data={addGroupData(ele.value)} />
      )}
    </>
  ));
}

const DataPage = () => {
  const location = useLocation();
  const {
    state: { formId, page },
  } = location;
  const {
    userStore: { isLoggedIn },
    formStore: { data, getFormData },
    companyStore: { fetchUnits },
  } = useStore();
  useEffect(() => {
    getFormData({ formId, type: page });
    fetchUnits();
  }, [fetchUnits, formId, getFormData, page]);
  const groupData = useMemo(
    () =>
      Object.entries(
        data?.reduce((acc, curr) => {
          if (acc?.[curr?.group?.[0]]) {
            return {
              ...acc,
              [curr?.group?.[0]]: [...acc[curr?.group?.[0]], curr],
            };
          }
          return {
            ...acc,
            [curr?.group?.[0]]: [curr],
          };
        }, {})
      ).map(([key, value]) => ({
        group: key,
        value,
        label: STATIC_GROUPS[page][key],
      })),
    [data, page]
  );
  return (
    <Layout>
      <div className="add-water-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">List Of Details</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item text-capitalize">
                      <Link
                        to={isLoggedIn ? `/company/${page}` : `/admin/${page}`}
                      >
                        {page === "aqi" ? "Emission" : page} List
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">Form Details</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="card p-4">
            <div className="table-responsive ">
              <table className="table align-middle table-bordered table-nowrap mb-0">
                <thead>
                  <tr className="waterTabletr">
                    <th>Sr. No.</th>
                    <th>Title</th>
                    <th>Unit</th>
                    <th>Frequency</th>
                    <th>Current Value</th>
                    <th>Target Value</th>
                    <th>Comment</th>
                    <th>Attachment</th>
                  </tr>
                </thead>
                <tbody>
                  {groupData?.map((raw, id) => (
                    <>
                      <tr className="table-heading">
                        <td>{id + 1}</td>
                        <td colSpan={7}>{raw.label}</td>
                      </tr>
                      <FormTableBody data={raw} page={page} />
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default observer(DataPage);

import { useCallback } from "react";
import GroupStackedChart from "../../components/charts/groupStackedChart";
import { filterData } from "../../helpers/groupDataCalculations";

function EmployeeHireGraph({ data, year, categories }) {
  const totalSum = useCallback(
    (data) => data?.reduce((acc, curr) => curr.value + acc, 0) || 0,
    []
  );
  const current = filterData(data, "year", year);
  const previous = filterData(data, "year", year - 1);
  const chartData = [
    {
      name: "Male",
      group: "first",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_A_A")),
        totalSum(filterData(current, "fieldGroup", "A_A_A")),
      ],
    },
    {
      name: "Female",
      group: "first",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_A_B")),
        totalSum(filterData(current, "fieldGroup", "A_A_B")),
      ],
    },
    {
      name: "Local Employee",
      group: "second",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_A_C")),
        totalSum(filterData(current, "fieldGroup", "A_A_C")),
      ],
    },

    {
      name: "OutSourced Employee",
      group: "second",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_A_D")),
        totalSum(filterData(current, "fieldGroup", "A_A_D")),
      ],
    },
  ];
  const secondChartData = [
    {
      name: "Male",
      group: "first",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_B_A")),
        totalSum(filterData(current, "fieldGroup", "A_B_A")),
      ],
    },
    {
      name: "Female",
      group: "first",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_B_B")),
        totalSum(filterData(current, "fieldGroup", "A_B_B")),
      ],
    },
    {
      name: "Local Employee",
      group: "second",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_B_C")),
        totalSum(filterData(current, "fieldGroup", "A_B_C")),
      ],
    },

    {
      name: "OutSourced Employee",
      group: "second",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_B_D")),
        totalSum(filterData(current, "fieldGroup", "A_B_D")),
      ],
    },
  ];
  const thirdChartData = [
    {
      name: "Return to Work Male",
      group: "first",
      data: [
        totalSum(
          filterData(
            filterData(previous, "fieldGroup", "C_E_A"),
            "fieldName",
            "Male"
          )
        ),
        totalSum(
          filterData(
            filterData(current, "fieldGroup", "C_E_A"),
            "fieldName",
            "Male"
          )
        ),
      ],
    },
    {
      name: "Return to Work Female",
      group: "first",
      data: [
        totalSum(
          filterData(
            filterData(previous, "fieldGroup", "C_E_A"),
            "fieldName",
            "Female"
          )
        ),
        totalSum(
          filterData(
            filterData(current, "fieldGroup", "C_E_A"),
            "fieldName",
            "Female"
          )
        ),
      ],
    },
    {
      name: "Retention Male",
      group: "second",
      data: [
        totalSum(
          filterData(
            filterData(previous, "fieldGroup", "C_E_B"),
            "fieldName",
            "Male"
          )
        ),
        totalSum(
          filterData(
            filterData(current, "fieldGroup", "C_E_B"),
            "fieldName",
            "Male"
          )
        ),
      ],
    },
    {
      name: "Retention Female",
      group: "second",
      data: [
        totalSum(
          filterData(
            filterData(previous, "fieldGroup", "C_E_B"),
            "fieldName",
            "Female"
          )
        ),
        totalSum(
          filterData(
            filterData(current, "fieldGroup", "C_E_B"),
            "fieldName",
            "Female"
          )
        ),
      ],
    },
  ];

  return (
    <div className="">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex flex-wrap align-items-start">
            <h5 className="mb-3 text-muted">
              New Employee Hires And employee turnover (in Numbers)
            </h5>
          </div>
          <div className="col-xl-12 d-flex flex-wrap ">
            <div className="col-xl-4 border-end  ">
              <p className="mb-3 text-muted   ">
                Total number and rate of new employee hires during the reporting
                period
              </p>

              <GroupStackedChart categories={categories} series={chartData} />
            </div>
            <div className="col-xl-4 border-end ">
              <p className="mb-3 text-muted  px-3 ">
                Total number and rate of employee turnover during the reporting
                period
              </p>

              <GroupStackedChart
                categories={categories}
                series={secondChartData}
              />
            </div>
            <div className="col-xl-4">
              <p className="mb-3 text-muted px-3 ">
                Return to work and retention rates of employees that took
                parental leave, by gender.
              </p>

              <GroupStackedChart
                categories={categories}
                series={thirdChartData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function OhcChart({ data, year, categories }) {
  const totalSum = useCallback(
    (data) => data?.reduce((acc, curr) => curr.value + acc, 0) || 0,
    []
  );
  const current = filterData(data, "year", year);
  const previous = filterData(data, "year", year - 1);
  const chartData = [
    "Number of Employees whose Work/Workplace is controlled by the Organisation.",
    "Number of Employees Covered by the OHS System and internally audited.",
    "Number of Employees covered by the OHS System, Audited or Certified by an External party.",
    "Number of Employees Covered by the OHS System",
    "Number of Workers Excluded.",
  ].map((e, i) => ({
    name: e,
    group: i === 4 ? "second" : "first",
    data: [
      totalSum(filterData(previous, "fieldName", e)),
      totalSum(filterData(current, "fieldName", e)),
    ],
  }));

  const secondChartData = [
    {
      label: "Fatalities (as a result of work-related injury)",
      value:
        "The number and rate of fatalities as a result of work-related injury.",
      group: "I_A",
    },
    {
      value:
        "The number and rate of high-consequence work-related injuries (excluding fatalities)",
      group: "I_A",
      label: "High-consequence work-related injuries (excluding fatalities",
    },
    {
      value: "The number and rate of recordable work-related injuries",
      label: "Recordable work-related injuries",
      group: "I_A",
    },
    {
      value: "The number of hours worked.",
      label: "Man hours worked",
      group: "I_A",
    },
  ].map((e) => ({
    name: e.group === "I_A" ? e.label + " " : e.label,
    group: e.group,
    data: [
      totalSum(
        previous?.filter(
          (f) => f?.fieldName === e.value && f?.fieldGroup === e.group
        )
      ),
      totalSum(
        current?.filter(
          (f) => f?.fieldName === e.value && f?.fieldGroup === e.group
        )
      ),
    ],
  }));
  const thirdChartData = [
    {
      value:
        "The number and rate of fatalities as a result of work-related injury",
      label: "Fatalities (as a result of work-related injury)",
      group: "I_B",
    },
    {
      value:
        "The number and rate of high-consequence work-related injuries (excluding fatalities)",
      group: "I_B",
      label: "High-consequence work-related injuries (excluding fatalities",
    },
    {
      value: "The number and rate of recordable work-related injuries",
      group: "I_B",
      label: "Recordable work-related injuries",
    },
    {
      value: "The number of hours worked.",
      group: "I_B",
      label: "Man hours worked",
    },
  ].map((e) => ({
    name: e.group === "I_A" ? e.label + " " : e.label,
    group: e.group,
    data: [
      totalSum(
        previous?.filter(
          (f) => f?.fieldName === e.value && f?.fieldGroup === e.group
        )
      ),
      totalSum(
        current?.filter(
          (f) => f?.fieldName === e.value && f?.fieldGroup === e.group
        )
      ),
    ],
  }));

  const fourthChart = [
    {
      label: "Fatalities",
      value: "The number of fatalities as a result of work-related ill health;",
      group: "J_A",
    },
    {
      value: "The number of cases of recordable work-related ill health;",
      label: "Recordable case",
      group: "J_A",
    },
  ].map((e) => ({
    name: e.group === "I_A" ? e.label + " " : e.label,
    group: e.group,
    data: [
      totalSum(
        previous?.filter(
          (f) => f?.fieldName === e.value && f?.fieldGroup === e.group
        )
      ),
      totalSum(
        current?.filter(
          (f) => f?.fieldName === e.value && f?.fieldGroup === e.group
        )
      ),
    ],
  }));
  return (
    <div className="">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex flex-wrap align-items-start">
            <h5 className="mb-3 text-muted">OH&C (in Numbers)</h5>
          </div>
          <div>
            <div className="border-bottom pb-3">
              <p className="text-muted ">
                Workers covered by an occupational health and safety management
                system
              </p>

              <GroupStackedChart categories={categories} series={chartData} />
            </div>
            <div className="border-bottom mb-3">
              <h6 className="font-weight-bold  mt-3 text-muted ">
                Work Related Injuries.
              </h6>

              <div className="d-flex flex-wrap col-xl-12">
                <div className="col-xl-6  border-end ">
                  <p className="text-muted  ">For all employees:</p>
                  <GroupStackedChart
                    categories={categories}
                    series={secondChartData}
                  />
                </div>
                <div className="col-xl-6">
                  <p className="text-muted px-3">
                    For all workers:
                  </p>

                  <GroupStackedChart
                    categories={categories}
                    series={thirdChartData}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <p className="text-muted  ">Work-related ill health</p>
              <GroupStackedChart categories={categories} series={fourthChart} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TrainingAndEducationGraph({ data, year, categories }) {
  const totalSum = useCallback(
    (data) => data?.reduce((acc, curr) => curr.value + acc, 0) || 0,
    []
  );
  const current = filterData(data, "year", year);
  const previous = filterData(data, "year", year - 1);
  const chartData = [
    {
      name: "Male",
      group: "male",
      data: [
        totalSum(filterData(previous, "fieldName", "Male")),
        totalSum(filterData(current, "fieldName", "Male")),
      ],
    },
    {
      name: "Female",
      group: "male",
      data: [
        totalSum(filterData(previous, "fieldName", "Female")),
        totalSum(filterData(current, "fieldName", "Female")),
      ],
    },
  ];
  return (
    <div className="">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex flex-wrap align-items-start">
            <h5 className="mb-3 text-muted">
              Average hours of training that the organization’s employees have
              undertaken during the reporting period (in Numbers)
            </h5>
          </div>
          <div>
            <GroupStackedChart categories={categories} series={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
}

function DiversityAndEqualOpportunityChart({ data, year, categories }) {
  const totalSum = useCallback(
    (data) => data?.reduce((acc, curr) => curr.value + acc, 0) || 0,
    []
  );
  const current = filterData(data, "year", year);
  const previous = filterData(data, "year", year - 1);

  const chartData = [
    {
      name: "Male",
      group: "first",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_A_A")),
        totalSum(filterData(current, "fieldGroup", "A_A_A")),
      ],
    },
    {
      name: "Female",
      group: "first",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_A_B")),
        totalSum(filterData(current, "fieldGroup", "A_A_B")),
      ],
    },
  ];
  const chartDataSecond = [
    {
      name: "Male",
      group: "second",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_B_A")),
        totalSum(filterData(current, "fieldGroup", "A_B_A")),
      ],
    },
    {
      name: "Female",
      group: "second",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_B_B")),
        totalSum(filterData(current, "fieldGroup", "A_B_B")),
      ],
    },
  ];
  return (
    <div className="">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex flex-wrap align-items-start">
            <h5 className="mb-3 text-muted">
              Diversity of governance bodies and employees (in Numbers)
            </h5>
          </div>
          <div className="col-xl-12 d-flex flex-wrap align-items-start">
            <div className="col-xl-6 border-end ">
              <p className="text-muted  ">
                Percentage of individuals within the organization’s governance
                bodies in each of the following diversity categories:
              </p>

              <GroupStackedChart categories={categories} series={chartData} />
            </div>
            <div className="col-xl-6">
              <p className="text-muted px-3 ">
                Percentage of employees per employee category in each of the
                following diversity categories
              </p>

              <GroupStackedChart
                categories={categories}
                series={chartDataSecond}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function NonDiscriminationChart({ data, year, categories }) {
  const totalSum = useCallback(
    (data) => data?.reduce((acc, curr) => curr.value + acc, 0) || 0,
    []
  );
  const current = filterData(data, "year", year);
  const previous = filterData(data, "year", year - 1);
  const chartData = [
    {
      label:
        "Total number of incidents of discrimination during the reporting period.",
      value:
        "Total number of incidents of discrimination during the reporting period.",
      group: "A",
    },
    {
      label: "Incident reviewed by the organization.",
      value: "Incident reviewed by the organization.",
      group: "B",
    },
    {
      label: "Remediation plans being implemented.",
      value: "Remediation plans being implemented.",
      group: "B",
    },
    {
      label:
        "Remediation plans that have been implemented, with results reviewed through routine internal management review processes.",
      value:
        "Remediation plans that have been implemented, with results reviewed through routine internal management review processes.",
      group: "B",
    },
    {
      label: "Incident no longer subject to action.",
      value: "Incident no longer subject to action.",
      group: "B",
    },
  ].map((e) => ({
    name: e.label,
    group: e.group,
    data: [
      totalSum(filterData(previous, "fieldName", e.value)),
      totalSum(filterData(current, "fieldName", e.value)),
    ],
  }));
  return (
    <div className="">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex flex-wrap align-items-start">
            <h5 className="mb-3 text-muted">Non-discrimination (in Numbers)</h5>
          </div>
          <div>
            <GroupStackedChart categories={categories} series={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
}

function SupplierSocialAssesmentChart({ data, year, categories }) {
  const totalSum = useCallback(
    (data) => data?.reduce((acc, curr) => curr.value + acc, 0) || 0,
    []
  );
  const current = filterData(data, "year", year);
  const previous = filterData(data, "year", year - 1);

  const chartData = [
    {
      label:
        "Percentage of new suppliers that were screened using social criteria.",
      value:
        "Percentage of new suppliers that were screened using social criteria.",
      group: "A",
    },
    {
      value: "Number of suppliers assessed for social impacts.",
      group: "B",
      label: "Number of suppliers assessed for social impacts.",
    },
    {
      value:
        "Number of suppliers identified as having significant actual and potential negative social impacts",
      label:
        "Number of suppliers identified as having significant actual and potential negative social impacts",
      group: "B",
    },
    {
      value: "% of Supplier improvement rate.",
      label: "% of Supplier improvement rate.",
      group: "B",
    },
    {
      value:
        "Percentage of suppliers terminated due to Social impact findings.",
      label:
        "Percentage of suppliers terminated due to Social impact findings.",
      group: "B",
    },
  ].map((e) => ({
    name: e.label,
    group: e.group,
    data: [
      totalSum(
        previous?.filter(
          (f) => f?.fieldName === e.value && f?.fieldGroup === e.group
        )
      ),
      totalSum(
        current?.filter(
          (f) => f?.fieldName === e.value && f?.fieldGroup === e.group
        )
      ),
    ],
  }));
  return (
    <div className="">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex flex-wrap align-items-start">
            <h5 className="mb-3 text-muted">Supplier Social Assessment (in Numbers)</h5>
          </div>
          <div>
            <GroupStackedChart categories={categories} series={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
}

function CustomerHealthAndSafetyChart({ data, year, categories }) {
  const totalSum = useCallback(
    (data) => data?.reduce((acc, curr) => curr.value + acc, 0) || 0,
    []
  );
  const current = filterData(data, "year", year);
  const previous = filterData(data, "year", year - 1);
  const chartData = [
    {
      label:
      "Percentage of significant product and service categories for which health and safety impacts are assessed for improvement",
      value:
        "Percentage of significant product and service categories for which health and safety impacts are assessed for improvement",
      group: "A",
    },
    {
      label:
        "Incidents of non-compliance with regulations resulting in a fine or penalty",
      value:
        "Incidents of non-compliance with regulations resulting in a fine or penalty",
      group: "B_A",
    },
    {
      label:
        "Incidents of non-compliance with regulations resulting in a warning;",
      value:
        "Incidents of non-compliance with regulations resulting in a warning;",
      group: "B_A",
    },
    {
      label: "Incidents of non-compliance with voluntary codes.",
      value: "Incidents of non-compliance with voluntary codes.",
      group: "B_A",
    },
  ].map((e) => ({
    name: e.label,
    group: e.group,
    data: [
      totalSum(filterData(previous, "fieldName", e.value)),
      totalSum(filterData(current, "fieldName", e.value)),
    ],
  }));
  return (
    <div className="">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex flex-wrap align-items-start">
            <h5 className="mb-3 text-muted">Customer Health and Safety (in Numbers)</h5>
          </div>
          <div>
            <GroupStackedChart categories={categories} series={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
}

function MarketAndLabellingChart({ data, year, categories }) {
  const totalSum = useCallback(
    (data) => data?.reduce((acc, curr) => curr.value + acc, 0) || 0,
    []
  );
  const current = filterData(data, "year", year);
  const previous = filterData(data, "year", year - 1);
  const chartData = [
    {
      label:
        "Incidents of non-compliance with regulations resulting in a fine or penalty",
      value:
        "Incidents of non-compliance with regulations resulting in a fine or penalty",
      group: "A",
    },
    {
      label:
        "Incidents of non-compliance with regulations resulting in a warning",
      value:
        "Incidents of non-compliance with regulations resulting in a warning",
      group: "A",
    },
    {
      label: "Incidents of non-compliance with voluntary codes",
      value: "Incidents of non-compliance with voluntary codes",
      group: "A",
    },
  ].map((e) => ({
    name: e.label,
    group: e.group,
    data: [
      totalSum(filterData(previous, "fieldName", e.value)),
      totalSum(filterData(current, "fieldName", e.value)),
    ],
  }));

  const secondChartData = [
    {
      label:
        "Incidents of non-compliance with regulations resulting in a fine or penalty;",
      value:
        "Incidents of non-compliance with regulations resulting in a fine or penalty;",
      group: "B",
    },
    {
      label:
        "Incidents of non-compliance with regulations resulting in a warning;",
      value:
        "Incidents of non-compliance with regulations resulting in a warning;",
      group: "B",
    },
    {
      label: "Incidents of non-compliance with voluntary codes.",
      value: "Incidents of non-compliance with voluntary codes.",
      group: "B",
    },
  ].map((e) => ({
    name: e.label,
    group: e.group,
    data: [
      totalSum(filterData(previous, "fieldName", e.value)),
      totalSum(filterData(current, "fieldName", e.value)),
    ],
  }));

  return (
    <div className="">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex flex-wrap align-items-start">
            <h5 className="mb-3 text-muted">Marketing and Labeling (in Numbers)</h5>
          </div>

          <div className="col-xl-12 d-flex flex-wrap align-items-start">
            <div className="col-xl-6 border-end ">
              <p className="text-muted  ">
                Incidents of non-compliance concerning product and service
                information and labeling
              </p>
              <GroupStackedChart categories={categories} series={chartData} />
            </div>
            <div className="col-xl-6">
              <p className="text-muted px-3 ">
                Incidents of non-compliance concerning marketing communications
              </p>
              <GroupStackedChart
                categories={categories}
                series={secondChartData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CustomerAndPrivacyChart({ data, year, categories }) {
  const totalSum = useCallback(
    (data) => data?.reduce((acc, curr) => curr.value + acc, 0) || 0,
    []
  );
  const current = filterData(data, "year", year);
  const previous = filterData(data, "year", year - 1);
  const chartData = [
    {
      label:
        "Complaints received from outside parties and substantiated by the organization",
      value:
        "Complaints received from outside parties and substantiated by the organization",
      group: "A",
    },
    {
      label: "Complaints from regulatory bodies",
      value: "Complaints from regulatory bodies",
      group: "A",
    },
    {
      label:
        "Total number of identified leaks, thefts, or losses of customer data",
      value:
        "Total number of identified leaks, thefts, or losses of customer data",
      group: "B",
    },
  ].map((e) => ({
    name: e.label,
    group: e.group,
    data: [
      totalSum(filterData(previous, "fieldName", e.value)),
      totalSum(filterData(current, "fieldName", e.value)),
    ],
  }));

  return (
    <div className="">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex flex-wrap align-items-start">
            <h5 className="mb-3 text-muted">Customer Privacy (in Numbers)</h5>
          </div>
          <div>
            <GroupStackedChart categories={categories} series={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export {
  EmployeeHireGraph,
  OhcChart,
  TrainingAndEducationGraph,
  DiversityAndEqualOpportunityChart,
  NonDiscriminationChart,
  CustomerHealthAndSafetyChart,
  MarketAndLabellingChart,
  CustomerAndPrivacyChart,
  SupplierSocialAssesmentChart,
};

import { useMemo } from "react";
import useStore from "../../hooks/useStore";
import { STATIC_GROUPS } from "../../constants/constants";
import BarChart from "../charts/barChart";
import { observer } from "mobx-react";
import ColumnChart from "../charts/columnChart";

function WasteDashboardAccordion({ year }) {
  const {
    dashboardStore: { dashboardDetails },
    companyStore: { revenuePageData },
  } = useStore();
  const barChartsGroupData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.waste;
    const result = ["B", "C", "F"].map((group) => {
      const data = [year - 1, year].map((year) => {
        const temp = tempData?.[year]?.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        return temp?.[group]?.prevValue || 0;
      });
      return {
        label: STATIC_GROUPS.waste[group],
        data,
      };
    });
    return result;
  }, [dashboardDetails?.charts?.waste, year]);

  // const data = revenuePageData?.fieldData?.filter(
  //   (e) => e?.type === "waste" && e?.title?.includes("Revenue")
  // );

  // const chartData = data
  //   ?.filter((e) => year?.toString() === e?.year?.toString())
  //   ?.map((ele) => ({
  //     name: ele?.title,
  //     data: [year - 1, year].map(
  //       (f) =>
  //         data?.find(
  //           (d) =>
  //             f?.toString() === d?.year?.toString() && ele?.title === d?.title
  //         )?.value || 0
  //     ),
  //   }));

  return (
    <div className="row h-100  ">
      {barChartsGroupData.length !== 0 &&
        barChartsGroupData?.map?.(({ label, data }, index) => (
          <div key={index} className="col-xl-6  mb-3">
            <div className="card h-100  ">
              <div className="card-body">
                <div className="d-flex  justify-content-between ">
                  <h4 className="card-title mb-4 donut-heading">
                    {label}
                    {/* {index === 0 ? "(million)" : "(Kl)"} */}
                  </h4>

                  <div className="ms-auto">
                    <div className="toolbar d-flex flex-wrap gap-2 ">
                      {" "}
                      <div className="">
                        <p className="text-muted mb-1">Unit</p>
                        <h6>{dashboardDetails?.charts?.waste?.unit}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <BarChart
                  series={[{ data, name: label }] || []}
                  categories={[
                    `FY${(year - 1).toString().slice(2)}`,
                    `FY${year.toString().slice(2)}`,
                  ]}
                  unit={dashboardDetails?.charts?.waste?.unit}
                />
              </div>
            </div>
          </div>
        ))}
      {/* <div className="card">
        <div className="card-body">
          <ColumnChart
            data={chartData}
            yaxis={[]}
            categories={[
              `FY${(year - 1).toString().slice(2)}`,
              `FY${year.toString().slice(2)}`,
            ]}
          />
        </div>
      </div> */}
    </div>
  );
}

export default observer(WasteDashboardAccordion);

import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FORM_FIELDS,
  FREQUENCY_CONSTANTS,
  STATIC_GROUPS,
  WASTE_UNITS_1,
} from "../../../../../constants/constants";
import FormDropdown from "../../../../../components/formDropdown";
import { Accordion, Form } from "react-bootstrap";
import useStore from "../../../../../hooks/useStore";
import FormTableBody from "./formTableBody";
import { toJS } from "mobx";
import { useAuth } from "../../../../../context/authProvider";
import GlobalUnitDropDown from "../../../../../components/globalUnitDropDown";
import RecordExists from "../../../../../components/recordExists";

function AddWastePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { formData, groupsFilled, freq, formid, unitGlobal } =
    location.state || {};
  //store
  const { showAlert } = useAuth();
  const {
    userStore: { userData },
    companyStore: {
      fetchFields,
      fields,
      fetchUnits,
      companyUnit,
      kpiId,
      subCatId,
      catId,
      fetchFinancialData,
    },
    wasteStore: { create },
    formStore: { recordExists, formExists, setFormExists },
  } = useStore();
  //states
  const [groupPayload, setGroupPayload] = useState([]);
  const [frequency, setFrequency] = useState({
    ...FREQUENCY_CONSTANTS,
    ...freq,
  });
  const [validate, setValidate] = useState(false);
  const [expanded, setExpanded] = useState("A");
  const [editForm, setEditForm] = useState("");
  const [globalUnit, setGlobalUnit] = useState(unitGlobal || "");

  useEffect(() => {
    fetchFields();
    fetchUnits();
  }, [fetchFields, fetchUnits]);

  useEffect(() => {
    fetchFinancialData({
      year:
        frequency.frequencyYear ||
        new Date(frequency.frequencyDate).getFullYear(),
    });
  }, [fetchFinancialData, frequency.frequencyDate, frequency.frequencyYear]);

  const tempGroupPayload = useMemo(
    () =>
      formData ||
      Object.entries(
        fields?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.group[0]]: [
              ...(acc[curr.group[0]] ? acc[curr.group[0]] : []),
              { ...curr, ...FREQUENCY_CONSTANTS, ...FORM_FIELDS },
            ],
          }),
          {}
        )
      ).map(([key, value]) => {
        return {
          group: key,
          label: STATIC_GROUPS.waste[key],
          value: value.sort((a, b) => Number(a.position) - Number(b.position)),
          target: 0,
          prevValue: 0,
        };
      }),
    [fields, formData]
  );

  const allGroups = useMemo(
    () => Array.from(new Set(tempGroupPayload?.map(({ group }) => group))),
    [tempGroupPayload]
  );

  useEffect(() => {
    if (groupsFilled?.length > 0) {
      setExpanded(
        allGroups[
          allGroups.findIndex((ele) => ele === groupsFilled.slice(-1)[0]) + 1
        ]
      );
    }
  }, [allGroups, groupsFilled]);

  const dropdownValidated = useMemo(
    () =>
      (() => (frequency.frequencyType === "Daily" ? 2 : 3))() ===
      Object.values(frequency).filter((e) => e)?.length,
    [frequency]
  );

  useEffect(() => {
    setGroupPayload(tempGroupPayload);
  }, [tempGroupPayload]);

  const onCreate = useCallback(
    ({ data, frequency, groupsFilled, payload, formId, globalUnit }) => {
      create({
        data,
        companyId: Number(toJS(userData)?.companyId),
        companyUnit,
        frequency,
        kpiId,
        subCatId,
        catId,
        showAlert,
        navigate,
        totalGroups: tempGroupPayload.length,
        update: groupsFilled?.includes(data.group),
        groupData: payload,
        formId,
        setEditForm,
        globalUnit,
      });
    },
    [
      catId,
      companyUnit,
      create,
      kpiId,
      navigate,
      showAlert,
      subCatId,
      tempGroupPayload.length,
      userData,
    ]
  );

  useEffect(() => {
    setGroupPayload((prev) =>
      prev?.map((ele) => ({
        ...ele,
        value: ele?.value?.map((e) => ({ ...e, ...frequency })),
      }))
    );
  }, [frequency]);

  useEffect(() => {
    if (dropdownValidated && !formid) {
      recordExists({ frequency, type: "waste" });
    }
  }, [dropdownValidated, formid, frequency, recordExists]);

  useEffect(
    () => () => {
      setFormExists();
    },
    []
  );

  return (
    <div className="add-water-page">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0 font-size-18">Add Waste</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/company/water">Waste List</Link>
                </li>
                <li className="breadcrumb-item active">Add Waste</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="card p-4">
        <Form validated={validate}>
          <FormDropdown
            frequency={frequency}
            setFrequency={setFrequency}
            isDisabled={groupsFilled?.length > 0}
          />
          <GlobalUnitDropDown
            setData={setGroupPayload}
            filterUnit={WASTE_UNITS_1}
            global={globalUnit}
            setGlobal={setGlobalUnit}
            disabled={!!unitGlobal}
          />
        </Form>
        {formExists ? (
          <RecordExists />
        ) : (
          <Accordion
            className="water-table-accordion"
            defaultActiveKey={"A"}
            activeKey={expanded}
            onSelect={(eventKey) => {
              setExpanded(eventKey);
              setValidate(false);
            }}
          >
            <div className="table-responsive ">
              {groupPayload?.map((data, index) => (
                <Accordion.Item
                  key={`${data.group}-accordion-item`}
                  eventKey={data.group}
                >
                  <Accordion.Header>
                    <tr style={{ display: "flex", gap: "4px" }}>
                      <td>{index + 1}</td>
                      <td colSpan={7}>{data.label}</td>
                    </tr>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Form
                      noValidate
                      validated={validate}
                      onSubmit={(event) => {
                        event.preventDefault();
                        const form = event.target;
                        if (form.checkValidity() && dropdownValidated) {
                          onCreate({
                            data,
                            frequency,
                            groupsFilled,
                            payload: groupPayload,
                            formId: formid,
                            globalUnit,
                          });
                        } else {
                          showAlert("Please fill All fields.");
                        }
                        setValidate(true);
                      }}
                    >
                      <table className="table align-middle table-bordered table-nowrap mb-0">
                        <thead>
                          <tr key={12} className="waterTabletr">
                            <th key={0}>Sr. No.</th>
                            <th key={1}>Title</th>
                            <th key={2}>Unit</th>
                            <th key={3}>Frequency</th>
                            <th key={4}>Current Value</th>
                            <th key={5}>Target Value</th>
                            <th key={6}>Comment</th>
                            <th key={8}>Attachment</th>
                          </tr>
                        </thead>
                        <FormTableBody
                          data={data}
                          setData={setGroupPayload}
                          frequency={frequency}
                          editForm={editForm}
                          setEditForm={setEditForm}
                          allGroups={allGroups}
                        />
                      </table>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </div>
          </Accordion>
        )}
      </div>
    </div>
  );
}

export default observer(AddWastePage);

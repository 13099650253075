import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import useStore from "../../hooks/useStore";
import { useAuth } from "../../context/authProvider";
import { Accordion, Form } from "react-bootstrap";
import { REVENUE_FIELDS } from "../../constants/constants";
import Layout from "../../utils/layout";
import GlobalUnitDropDown from "../../components/globalUnitDropDown";
import info from "../../assets/icons/info.svg";
import CustomTooltip from "../../utils/tooltip/tooltip";
const Revenue = () => {
  const {
    companyStore: {
      fetchRevenueCalculated,
      fetchUnits,
      revenue,
      saveRevenueData,
      fetchRevenueData,
      revenuePageData,
    },
  } = useStore();
  const { showAlert } = useAuth();
  const [revenueData, setRevenueData] = useState(0); // Default to 1
  const [rWaste, setRWaste] = useState(0);
  const [rUnit, setRUnit] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [globalUnit, setGlobalUnit] = useState("");
  const [data, setData] = useState(
    REVENUE_FIELDS.map((e) => ({ ...e, value: 0 }))
  );
  const [expanded, setExpanded] = useState(0);
  useEffect(() => {
    fetchUnits();
  }, [fetchUnits]);

  useEffect(() => {
    fetchRevenueCalculated({ year });
    fetchRevenueData({ year });
  }, [fetchRevenueCalculated, fetchRevenueData, year]);

  useEffect(() => {
    if (revenuePageData?.revenue?.length > 0) {
      const r = revenuePageData?.revenue?.find((e) => e.type === "all");
      const rW = revenuePageData?.revenue?.find((e) => e.type === "waste");
      const fields = revenuePageData?.fieldData;
      setRevenueData(r?.value || 0);
      setRWaste(rW?.value || 0);
      setGlobalUnit(r?.unitId);
      setRUnit(rW?.unitId);
      setData((prev) =>
        prev?.map((e) => {
          const value = fields?.find((f) => f.title === e.label)?.value || 0;
          return { ...e, value };
        })
      );
    } else {
      setRevenueData(0);
      setRWaste(0);
      setGlobalUnit("");
      setRUnit("");
    }
  }, [revenuePageData]);

  useEffect(() => {
    setData((prev) =>
      prev?.map((e) => ({
        ...e,
        value: !e?.valKey
          ? revenue?.[e.type]?.[e.key]?.prevValue
          : (Number(revenue?.[e.type]?.[e.valKey]?.prevValue) || 0) /
            (revenueData || 1),
      }))
    );
  }, [revenue, revenueData]);

  const renderData = useMemo(
    () =>
      Object.entries(
        data?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.type]: [...(acc[curr.type] || []), { ...curr }],
          }),
          {}
        )
      )?.map(([heading, content]) => ({ heading, content })),
    [data]
  );

  const handleSubmit = useCallback(
    ({ data, year, revenue }) => {
      saveRevenueData({ showAlert, fieldData: data, year, revenue });
    },
    [saveRevenueData, showAlert]
  );
  return (
    <Layout>
      <div className="categories">
        <div className="container-fluid">
          {/* Page Title */}
          <div className="">
            <div className="">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Revenue</h4>
                  <div className="page-title-right"></div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className=" row ">
                      <div className="mb-3 col-lg-4 ">
                        <label className="form-label">Year</label>
                        <select
                          className="form-select"
                          value={year || new Date().getFullYear()}
                          onChange={(event) =>
                            setYear(Number(event.target.value))
                          }
                          required
                        >
                          {Array.from({ length: 5 })
                            .map((_, i) => new Date().getFullYear() - i)
                            .map((v) => (
                              <option key={v} value={v}>{`FY ${v}-${
                                v + 1
                              }`}</option>
                            ))}
                        </select>
                      </div>

                      <div className="col-lg-4">
                        <label className="form-label">Revenue/per year</label>
                        <input
                          type="number"
                          className="form-control w-100"
                          value={revenueData || ""}
                          onChange={(event) =>
                            setRevenueData(Number(event.target.value))
                          }
                          disabled={revenuePageData?.revenue?.length > 0}
                          placeholder="Enter Revenue"
                          required
                        />
                      </div>

                      <GlobalUnitDropDown
                        setData={(e) => {}}
                        filterUnit={[
                          "Revenue",
                          "Revenue in dollar",
                          "Revenue in euro",
                        ]}
                        global={globalUnit}
                        setGlobal={setGlobalUnit}
                        required
                        disabled={revenuePageData?.revenue?.length > 0}
                      />
                    </div>
                  </div>

                  <Form
                    onSubmit={(event) => {
                      event.preventDefault();
                      if (year && globalUnit) {
                        handleSubmit({
                          data,
                          year,
                          revenue: [
                            {
                              type: "all",
                              value: revenueData,
                              unit: globalUnit,
                            },
                            {
                              type: "waste",
                              value: rWaste,
                              unit: rUnit,
                            },
                          ],
                        });
                      } else {
                        showAlert("Please select unit and year !");
                      }
                    }}
                  >
                    <Accordion
                      className="water-table-accordion"
                      onSelect={(e) => setExpanded(e)}
                      activeKey={expanded}
                      defaultActiveKey={0}
                    >
                      <Accordion.Item eventKey={0}>
                        <Accordion.Header>
                          Revenue data for Water, Energy and Emission
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="table-responsive">
                            <table className="table align-middle table-nowrap table-hover">
                              {renderData?.map(({ heading, content }) => (
                                <>
                                  <tr key={`heading-${heading} `}>
                                    <td
                                      className="bg-light py-2 px-2"
                                      style={{
                                        fontWeight: "bold",
                                        textTransform: "capitalize",
                                      }}
                                      colSpan={2}
                                    >
                                      {heading}
                                    </td>
                                  </tr>
                                  {content?.map((ele, index) => (
                                    <tr key={`${heading}-${ele.label}-row`}>
                                      <td>
                                        <span
                                          style={{
                                            display: "flex",
                                            gap: "5px",
                                          }}
                                        >
                                          {ele.label}
                                          {ele?.guidance && (
                                            <CustomTooltip position="top" content={ele?.guidance}>
                                              <img src={info} alt="" />
                                            </CustomTooltip>
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control-plaintext"
                                          value={
                                            Number(ele.value).toFixed(3) || 0
                                          }
                                          readOnly
                                          name={`heading-${heading}-${index}`}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ))}
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey={1}>
                        <Accordion.Header>
                          Revenue data for Waste
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="table-responsive">
                            <table className="table align-middle table-nowrap table-hover">
                              <tr key={`waste-revenue-row`}>
                                <td>Waste</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={rWaste || ""}
                                    onChange={(e) =>
                                      setRWaste(e.target.value || 0)
                                    }
                                    disabled={
                                      revenuePageData?.revenue?.length > 0
                                    }
                                  />
                                </td>
                                <td>
                                  <div className="">
                                    <GlobalUnitDropDown
                                      setData={(e) => {}}
                                      filterUnit={[
                                        "Revenue",
                                        "Revenue in dollar",
                                        "Revenue in euro",
                                      ]}
                                      width="col-lg-8 mb-4"
                                      global={rUnit}
                                      setGlobal={setRUnit}
                                      required
                                      disabled={
                                        revenuePageData?.revenue?.length > 0
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <div className="p-3 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={revenuePageData?.revenue?.length > 0}
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default observer(Revenue);

import React, { useEffect, useState } from "react";
import user from "../../assets/images/users/user-dummy-img.jpg";
import logo from "../../assets/images/logo-dark-esg.png";
import whiteLogo from "../../assets/images/logo-icon.png";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../hooks/useStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Offcanvas } from "react-bootstrap";
import Sidebar from "../sidebar";
import { setLocalStorage } from "../../helpers/localStorage";

function Header() {
  const {
    companyStore: {
      getAllCompanyData,
      companyData,
      setCatId,
      catId,
      removeCompanyData,
      companyUnit,
      setUnit,
    },
    userStore: { userData, fetchEmployeeKpis },
    dashboardStore: { showAll, setShowAll },
  } = useStore();
  const headerCategory = toJS(companyData);
  const navigate = useNavigate();
  const [showProfile, setShowProfile] = useState(false);
  const [showAllUnitsData, setShowAllUnitsData] = useState(false);
  const [showUnit, setShowUnit] = useState(false);

  const {
    userStore: { logoutUser, isAdminLoggedIn, isLoggedIn,token,logoutSuperAdmin },
  } = useStore();

  const [showSidebar, setShowSidebar] = useState(false);
  const [showRightSidePanel, setShowRightSidePanel] = useState(false);

  const handleProfileDropdown = () => {
    setShowProfile(!showProfile);
  };
  const handleShowAllUnitsData = (e) => {
    setShowAllUnitsData(!showAllUnitsData);
    setShowAll(e.target.checked);
    setShowUnit((prev) => !prev);
  };
  useEffect(
    () => () => {
      setLocalStorage("showAllUnit", false);
    },
    []
  );
  const logoutHandler = async () => {
    try {
      if (isAdminLoggedIn) {
        navigate("/admin");
        logoutSuperAdmin();
      }
      if (isLoggedIn) {
        navigate("/company");
        await logoutUser();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCompanyData();
    // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    localStorage.setItem("companyUnit", event.target.value);
    setUnit(event.target.value);
    setShowUnit((prev) => !prev);
  };

  useEffect(() => {
    fetchEmployeeKpis();
  }, [fetchEmployeeKpis]);
  if (isLoggedIn) {
    return (
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link
                to={
                  userData?.role === "owner"
                    ? "/admin/dashboard"
                    : "/company/dashboard"
                }
                className="logo"
                onClick={() => {
                  removeCompanyData();
                }}
              >
                <h2>
                  <img src={logo} alt="" />
                  ESG <span>Mitra</span>
                </h2>
              </Link>

              {/* <button
                className="btn btn-sm sidebar-toggle-button"
                onClick={() => setShowSidebar(true)}
              >
                <i className="mdi mdi-menu-left"></i>
              </button> */}
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            <form className="app-search d-none  ">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                />
                <span className="bx bx-search-alt"></span>
              </div>
            </form>
          </div>

          <div className=" navbar-links">
            {isLoggedIn && (
              <div className="header-links d-flex justify-content-center align-items-center">
                {headerCategory?.assignCategory &&
                  headerCategory?.assignCategory?.length > 0 &&
                  headerCategory?.assignCategory?.map((ele) => (
                    <div
                      key={ele?.id}
                      onClick={() => {
                        setCatId(ele?.catId);
                        navigate("/company/dashboard");
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <h3 className={catId === ele?.catId ? "active-link" : ""}>
                        {ele?.catIdAssignCategory?.name}
                      </h3>
                    </div>
                  ))}
              </div>
            )}
          </div>

          <div className=" align-items-center d-flex">
            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect d-flex align-items-center"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => setShowUnit(!showUnit)}
              >
                Unit
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div
                className={`dropdown-menu dropdown-menu-end ${
                  showUnit ? "show" : ""
                } `}
              >
                <div className="p-2  d-flex flex-column  gap-3">
                  <div>
                    {isLoggedIn && (
                      <>
                        <label htmlFor="unit-select" className="unit-label">
                          Select Unit:
                        </label>
                        <label
                          htmlFor="unit-select"
                          className="unit-label-mobile"
                        >
                          Select Unit:
                        </label>

                        <select
                          id="unit-select"
                          className="form-control w-100"
                          value={companyUnit}
                          onChange={handleChange}
                        >
                          <option value="">Select Unit</option>
                          {toJS(companyData)?.assignUnit?.map((unit) => (
                            <option key={unit.id} value={unit.id}>
                              {unit.name}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                  </div>
                  {toJS(userData)?.role === "company" && (
                    <div className="form-check form-switch mb-1 d-flex flex-row-reverse gap-5  justify-content-end p-0 g  ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={showAll}
                        onChange={handleShowAllUnitsData}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                      >
                        Show all units
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="dropdown d-inline-block">
              <button
                type="button"
                onClick={handleProfileDropdown}
                className="btn header-item waves-effect d-flex align-items-center"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="rounded-circle header-profile-user"
                  src={user}
                  alt="Header Avatar"
                />
                <span
                  className="d-none d-xl-inline-block ms-1 text-capitalize"
                  key="t-henry"
                >
                  {userData?.role}
                </span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
              </button>
              <div
                className={`dropdown-menu dropdown-menu-end ${
                  showProfile ? "show" : ""
                } position-absolute m-0`}
              >
                <Link
                  className="dropdown-item"
                  to={
                    userData?.role === "owner"
                      ? "/admin/profile"
                      : "/company/profile"
                  }
                >
                  <i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
                  <span key="t-profile">Profile</span>
                </Link>

                <div className="dropdown-divider"></div>
                <button
                  onClick={logoutHandler}
                  className="dropdown-item text-danger"
                >
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                  <span key="t-logout">Logout</span>
                </button>
              </div>
            </div>
          </div>
          {/* right side panel trigger */}
          <div className="menu-button">
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item noti-icon"
              id="page-header-notifications-dropdown"
              onClick={() => setShowRightSidePanel(true)}
            >
              <i className="fa fa-bars"></i>
            </button>
          </div>
        </div>

        {/* sidebar for mobile  */}
        <Offcanvas show={showSidebar} onHide={() => setShowSidebar(false)}>
          <Offcanvas.Body>
            <Sidebar />
          </Offcanvas.Body>
        </Offcanvas>

        {/* menu items responsive */}
        <Offcanvas
          placement={"start"}
          show={showRightSidePanel}
          onHide={() => setShowRightSidePanel(false)}
        >
          <Offcanvas.Body>
            {isLoggedIn && (
              <div className=" right-side-panel  vertical-menu h-100 border-0 rounded-0 mb-0 card">
                <p>Menu</p>

                <div className="header-links d-flex flex-column  ">
                  {headerCategory?.assignCategory &&
                    headerCategory?.assignCategory?.length > 0 &&
                    headerCategory?.assignCategory?.map((ele) => (
                      <div
                        key={ele?.id}
                        onClick={() => {
                          setCatId(ele?.catId);
                          // navigate("/company/dashboard");
                          //in case of mobile view
                          setShowRightSidePanel(false);
                          setShowSidebar(true);
                        }}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <img
                          style={{ width: "14px", opacity: "0.8" }}
                          src={whiteLogo}
                          alt=""
                        />
                        <h3
                          style={{ marginTop: "8px" }}
                          className={catId === ele?.catId ? "active-link " : ""}
                        >
                          {ele?.catIdAssignCategory?.name}
                        </h3>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      </header>
    );
  }

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/admin/dashboard" className="logo">
              <h2>
                <img src={logo} alt="" />
                ESG <span>Mitra</span>
              </h2>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 header-item waves-effect"
            id="vertical-menu-btn"
          >
            <i className="fa fa-fw fa-bars"></i>
          </button>

          <form className="app-search d-none d-lg-block">
            <div className="position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
              />
              <span className="bx bx-search-alt"></span>
            </div>
          </form>
        </div>

        <div className="d-flex">
          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-notifications-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="bx bx-bell bx-tada"></i>
              <span className="badge bg-danger rounded-pill">3</span>
            </button>
          </div>

          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item waves-effect"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={handleProfileDropdown}
            >
              <img
                className="rounded-circle header-profile-user"
                src={user}
                alt="Header Avatar"
              />
              <span
                className="d-none d-xl-inline-block ms-1 text-capitalize"
                key="t-henry"
              >
                Admin
              </span>
              <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div
              className={`dropdown-menu dropdown-menu-end ${
                showProfile ? "show" : ""
              } position-absolute m-0`}
            >
              <Link className="dropdown-item" to="/admin/profile">
                <i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
                <span key="t-profile">Profile</span>
              </Link>
              <Link className="dropdown-item d-block" to="/admin/dashboard">
                <span className="badge bg-success float-end">11</span>
                <i className="bx bx-wrench font-size-16 align-middle me-1"></i>{" "}
                <span key="t-settings">Settings</span>
              </Link>
              <div
                className="dropdown-item text-danger"
                onClick={logoutHandler}
                style={{ cursor: "pointer" }}
              >
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
                <span key="t-logout">Logout</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default observer(Header);

import {
  observable,
  makeObservable,
  action,
  computed,
  toJS,
  reaction,
} from "mobx";
import fetchData from "../utils/fetchData";
import { PrimaryRoute, SecondaryRoute } from "../constants/api";
import { getLocalStorage, setLocalStorage } from "../helpers/localStorage";

export default class DashboardStore {
  categories = [];
  subCategories = [];
  kpi = [];
  unit = [];
  fields = [];
  searchCategory = "";
  searchSubCategory = "";
  searchKpi = "";
  searchUnit = "";
  searchFields = "";
  count = null;
  dashboardDetailsRaw = {};
  companyUnit = getLocalStorage("companyUnit");
  showAll = false;
  reset() {
    this.categories = [];
    this.subCategories = [];
    this.kpi = [];
    this.unit = [];
    this.fields = [];
    this.searchCategory = "";
    this.searchSubCategory = "";
    this.searchKpi = "";
    this.searchUnit = "";
    this.searchFields = "";
    this.count = "";
    this.dashboardDetailsRaw = {};
  }

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.companyUnit = getLocalStorage("companyUnit");

    makeObservable(this, {
      categories: observable,
      subCategories: observable,
      showAll: observable,
      kpi: observable,
      unit: observable,
      fields: observable,
      searchCategory: observable,
      searchKpi: observable,
      searchSubCategory: observable,
      searchUnit: observable,
      searchFields: observable,
      count: observable,
      dashboardDetailsRaw: observable,
      fetchCategories: action.bound,
      fetchSubCategories: action.bound,
      fetchKpi: action.bound,
      fetchUnit: action.bound,
      fetchFields: action.bound,
      categoryList: computed,
      subCategoryList: computed,
      kpiList: computed,
      unitList: computed,
      fieldsList: computed,
      dashboardDetails: computed,
      createCategory: action.bound,
      createKpi: action.bound,
      createSubCategory: action.bound,
      createUnit: action.bound,
      createFields: action.bound,
      updateCategory: action.bound,
      updateSubCategory: action.bound,
      updateKpi: action.bound,
      updateUnit: action.bound,
      updateFields: action.bound,
      filterCategories: action.bound,
      filterKpi: action.bound,
      filterUnit: action.bound,
      filterSubCategories: action.bound,
      filterFields: action.bound,
      fetchCount: action.bound,
      reset: action.bound,
      fetchDashboardDetails: action.bound,
      setUnit: action.bound,
      setShowAll: action.bound,
    });
  }

  setShowAll(value) {
    this.showAll = value;
  }

  fetchCategories() {
    fetchData(`${PrimaryRoute.CategoryMaster}getAllCategory?limit=1000`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then(({ data }) => {
        this.categories = data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchCount() {
    fetchData("super-admin/getDashboardCounts", {
      method: "GET",
    })
      .then((res) => res.json())
      .then(({ data }) => {
        this.count = data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchSubCategories() {
    fetchData(
      `${PrimaryRoute.SubCategoryMaster}${SecondaryRoute.GetAllSubCategory}?limit=1000`,
      { method: "POST" }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        this.subCategories = data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchKpi() {
    fetchData(`${PrimaryRoute.Kpi}${SecondaryRoute.GetAllKpi}?limit=1000`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then(({ data }) => {
        this.kpi = data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchUnit() {
    fetchData(`${PrimaryRoute.UnitMaster}${SecondaryRoute.GetAllUnit}`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then(({ data }) => {
        this.unit = data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  fetchFields(payload) {
    fetchData(
      `${PrimaryRoute.FieldMaster}${SecondaryRoute.GetAllFields}?limit=1000`,
      {
        method: "POST",
        body: payload,
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        this.fields = data;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  setUnit(payload) {
    this.companyUnit = payload;
    setLocalStorage("companyUnit", payload);
  }
  get categoryList() {
    return toJS(this.categories)?.filter((val) =>
      val.name.toLowerCase().includes(this.searchCategory)
    );
  }

  get subCategoryList() {
    const subCategories = toJS(this.subCategories);
    const searchTerm = this.searchSubCategory.toLowerCase();
    return subCategories?.filter((val) =>
      val.name.toLowerCase().includes(searchTerm)
    );
  }

  get kpiList() {
    const kpi = toJS(this.kpi);
    const searchTerm = this.searchKpi.toLowerCase();
    return kpi?.filter((val) => val.name.toLowerCase().includes(searchTerm));
  }

  get unitList() {
    return toJS(this.unit)?.filter((val) =>
      val.name.toLowerCase().includes(this.searchUnit)
    );
  }

  get fieldsList() {
    return toJS(this.fields)?.filter((val) =>
      val.name.toLowerCase().includes(this.searchFields)
    );
  }

  get dashboardDetails() {
    return toJS(this.dashboardDetailsRaw);
  }

  createCategory(payload) {
    fetchData(`${PrimaryRoute.CategoryMaster}${SecondaryRoute.Create}`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then(() => {
        this.fetchCategories();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  createSubCategory(payload) {
    fetchData(`${PrimaryRoute.SubCategoryMaster}${SecondaryRoute.Create}`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          this.fetchSubCategories();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  createKpi(payload) {
    fetchData(`${PrimaryRoute.Kpi}${SecondaryRoute.Create}`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          this.fetchKpi();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  createUnit(payload) {
    fetchData(`${PrimaryRoute.UnitMaster}${SecondaryRoute.Create}`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          this.fetchUnit();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  createFields(payload, fieldsPayload) {
    fetchData(`${PrimaryRoute.FieldMaster}${SecondaryRoute.Create}`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          if (Object.values(fieldsPayload).every((val) => val)) {
            this.fetchFields(JSON.stringify(fieldsPayload));
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updateCategory(payload, id) {
    const params = new URLSearchParams();
    params.append("cmId", id);
    fetchData(
      `${PrimaryRoute.CategoryMaster}${SecondaryRoute.UpdateCategory}?${params}`,
      {
        method: "POST",
        body: JSON.stringify(payload),
        params: { cmId: id },
      }
    )
      .then((res) => res.json())
      .then(() => {
        this.fetchCategories();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updateSubCategory(payload, id) {
    const params = new URLSearchParams();
    params.append("cmId", id);
    fetchData(
      `${PrimaryRoute.SubCategoryMaster}${SecondaryRoute.Update}?${params}`,
      {
        method: "POST",
        body: JSON.stringify(payload),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          this.fetchSubCategories();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  updateKpi(payload, id) {
    const params = new URLSearchParams();
    params.append("cmId", id);
    fetchData(`${PrimaryRoute.Kpi}${SecondaryRoute.Update}?${params}`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          this.fetchKpi();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  updateUnit(payload, id) {
    const params = new URLSearchParams();
    params.append("unitId", id);
    fetchData(`${PrimaryRoute.UnitMaster}${SecondaryRoute.Update}?${params}`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          this.fetchUnit();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updateFields(payload, id) {
    const params = new URLSearchParams();
    params.append("fieldId", id);
    fetchData(`${PrimaryRoute.FieldMaster}${SecondaryRoute.Update}?${params}`, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          const { catId, subCatId, kpiId } = payload;
          this.fetchFields(JSON.stringify({ catId, subCatId, kpiId }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  filterCategories(value) {
    this.searchCategory = value;
  }

  filterSubCategories(value) {
    this.searchSubCategory = value;
  }

  filterKpi(value) {
    this.searchKpi = value;
  }

  filterUnit(value) {
    this.searchUnit = value;
  }

  filterFields(value) {
    this.searchFields = value;
  }

  fetchDashboardDetails({ year }) {
    const {
      companyStore: { companyUnit },
    } = this.rootStore;

    const params = new URLSearchParams();
    params.append("year", year || new Date().getFullYear());
    console.log(getLocalStorage("showAllUnit"));
    if (!this.showAll) {
      params.append("companyUnit", companyUnit);
    }
    fetchData(`company/dashboardDetails?${params.toString()}`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          this.dashboardDetailsRaw = data.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
}

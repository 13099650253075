import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Tab, Tabs } from "react-bootstrap";
import useStore from "../../hooks/useStore";
import { toJS } from "mobx";

import {
  EnvironmentDashboardTab,
  GovernanceDashboardTab,
  SocialDashboardTab,
} from "./dashboardTabs";
import { useAuth } from "../../context/authProvider";

const CompanyDashboard = () => {
  const { dashboardTab, setDashboardTab } = useAuth();
  // const [active, setActive] = useState("Environment");
  const {
    companyStore: { catId, companyData },
    userStore: { userData },
  } = useStore();
  const company = toJS(companyData);
  const assignCategory = company?.assignCategory || [];

  // Function to dynamically map catIdAssignCategory.name to corresponding tab component
  const getTabComponent = (name) => {
    switch (name) {
      case "Environment":
      return <EnvironmentDashboardTab />;
      case "Governance":
        return <GovernanceDashboardTab />;
      case "Social":
        return <SocialDashboardTab />;
      default:
        return null;
    }
  };

  const selectedCategory =
    toJS(companyData)?.assignCategory?.find((e) => e.catId === catId)
      ?.catIdAssignCategory?.name || "Environment";

  useEffect(() => {
    setDashboardTab(selectedCategory);
    // setActive(selectedCategory);
  }, [selectedCategory, setDashboardTab]);
  return (
    <div className="dashboard-content">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">WELCOME { userData?.firstName} { userData?.lastName},</h4>
          <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item text-capitalize">
                  <Link href="/company/dashboard">{ userData?.role}</Link>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Tabs
        className="mb-3 dashboard-tabs"
        id="dashboardtabmove"
        onSelect={(dashboardTab) => setDashboardTab(dashboardTab)}
        activeKey={dashboardTab}
      >
        {assignCategory.map((cat) => {
          const { catIdAssignCategory } = cat;
          const tabComponent = getTabComponent(catIdAssignCategory.name);
          return tabComponent ? (
            <Tab
              eventKey={catIdAssignCategory.name}
              title={catIdAssignCategory.name}
              key={cat.catId}
            >
              {tabComponent}
            </Tab>
          ) : null;
        })}
      </Tabs>
    </div>
  );
};

export default observer(CompanyDashboard);

import { observer } from "mobx-react";
import useStore from "../../../../hooks/useStore";
import { useEffect, useState } from "react";
import {
  GROUPS,
  GROUPS_WITH_TOTAL,
  NUMERIC_FIELDS,
  SMALL_GROUPS,
  SOCIAL_GROUP_CONSTANTS,
} from "../../../../constants/constants";
import { Accordion, Form } from "react-bootstrap";
import {
  convertToNestedFormat,
  getAllFieldValues,
  updateField,
} from "../../../../utils/helpers";
import numToRoman from "../../../../helpers/numToRoman";
import { useAuth } from "../../../../context/authProvider";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTooltip from "../../../../utils/tooltip/tooltip";
import info from "../../../../assets/icons/info.svg";

const formFields = {
  value: "",
  comment: "",
  attachment: "",
};

const AddForm = ({ year, groupConstant, routeSubPath }) => {
  // hooks
  const navigate = useNavigate();
  const location = useLocation();

  // global states
  const { formData, groupsFilled, formId } = location.state || {};
  const {
    formStore: { fields, fetchFields },
    social: { create },
  } = useStore();

  const { showAlert } = useAuth();
  const [validated, setValidated] = useState(false);

  // states
  const [expanded, setExpanded] = useState(0);
  const [data, setData] = useState([]);
  const [editForm, setEditForm] = useState("");
  const [attachment, setAttachment] = useState({});

  useEffect(() => {
    fetchFields();
  }, [fetchFields]);
  useEffect(() => {
    const tempData = convertToNestedFormat(
      fields.map((ele) => ({ ...ele, ...formFields })),
      SOCIAL_GROUP_CONSTANTS[groupConstant]
    );
    setData(formData || tempData);
  }, [fields, formData, groupConstant]);

  useEffect(() => {
    setExpanded(groupsFilled?.length || 0);
  }, [groupsFilled]);

  return (
    <div>
      <Accordion
        className="water-table-accordion"
        defaultActiveKey={0}
        activeKey={expanded}
        onSelect={(eventKey) => {
          setExpanded(eventKey);
        }}
      >
        {data?.map((accordion, index) => (
          <Accordion.Item
            key={`${accordion.group}-accordion-item`}
            eventKey={index}
          >
            <Accordion.Header>
              <tr style={{ display: "flex", gap: "4px" }}>
                <td>{index + 1}</td>
                <td colSpan={4}>
                  {SOCIAL_GROUP_CONSTANTS[groupConstant][accordion.group]}
                </td>
              </tr>
            </Accordion.Header>
            <Accordion.Body>
              <Form
                validated={validated}
                noValidate
                onSubmit={(event) => {
                  event.preventDefault();
                  if (year && event.target.checkValidity()) {
                    const payload = getAllFieldValues(accordion)?.map((ele) => {
                      if (!ele?.group?.includes("_")) {
                        const attach = attachment?.[ele?.fieldId] || "";
                        return {
                          ...ele,
                          attachment: attach,
                        };
                      } else {
                        return {
                          ...ele,
                          attachment:
                            ele?.attachment ||
                            attachment?.[ele.group.slice(0, 3)] ||
                            "",
                        };
                      }
                    });
                    create({
                      data,
                      payload,
                      update: groupsFilled?.includes(accordion.group),
                      formId,
                      showAlert,
                      navigate,
                      setEditForm,
                      totalGroups: data.length,
                      pageUrl: `/company/${routeSubPath}`,
                      year,
                    });
                    return;
                  }
                  setValidated(true);
                  showAlert(
                    !year
                      ? "Please select Year."
                      : "Please fill the required fields."
                  );
                }}
              >
                <table className="table align-middle table-bordered table-nowrap mb-0">
                  <thead>
                    <tr>
                      {[
                        "Sr. No.",
                        "Title",
                        "Value",
                        "Comment",
                        "Attachment",
                      ].map((head) => (
                        <th key={head}>{head}</th>
                      ))}
                    </tr>
                  </thead>
                  <TableBody
                    data={accordion}
                    key={`${accordion.group}-accordion-body-table-body`}
                    setData={setData}
                    attachment={attachment}
                    setAttachment={setAttachment}
                    editForm={editForm}
                    setEditForm={setEditForm}
                    groupConstant={groupConstant}
                  />
                </table>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

function TableBody({
  data,
  setData,
  attachment,
  setAttachment,
  editForm,
  setEditForm,
  groupConstant,
}) {
  const { groupsFilled } = useLocation().state || {};

  return (
    <tbody>
      {data?.value?.map((row, index) => {
        if (!row.label) {
          return groupsFilled?.includes(data.group) &&
            editForm !== data.group ? (
            <ShowRow
              data={row}
              sr={numToRoman(index + 1)}
              attachment={attachment}
              showAttachment
            />
          ) : (
            <Row
              data={row}
              sr={numToRoman(index + 1)}
              showAttachment
              attachment={attachment}
              setAttachment={setAttachment}
              setData={setData}
            />
          );
        }
        return (
          <>
            {groupsFilled?.includes(data.group) && editForm !== data.group ? (
              <ShowHeadRow
                label={row.label}
                sr={numToRoman(index + 1)}
                showAttachment
                attachment={attachment}
                group={row.group}
              />
            ) : (
              <HeadRow
                label={row.label}
                sr={numToRoman(index + 1)}
                showAttachment
                attachment={attachment}
                setAttachment={setAttachment}
                group={row.group}
              />
            )}
            {row?.value?.map((r, i) => {
              if (!r.label) {
                return groupsFilled?.includes(data.group) &&
                  editForm !== data.group ? (
                  <ShowRow data={r} sr={SMALL_GROUPS[i]} />
                ) : (
                  <Row data={r} sr={SMALL_GROUPS[i]} setData={setData} />
                );
              }
              return (
                <>
                  <HeadRow label={r.label} sr={SMALL_GROUPS[i]} />
                  {r?.value?.map((e) =>
                    groupsFilled?.includes(data.group) &&
                    editForm !== data.group ? (
                      <ShowRow data={e} sr="*" />
                    ) : (
                      <Row data={e} sr="*" setData={setData} />
                    )
                  )}
                  <RowTotal
                    value={r?.value?.reduce(
                      (acc, curr) => acc + (Number(curr.value) || 0),
                      0
                    )}
                  />
                </>
              );
            })}
            {GROUPS_WITH_TOTAL[groupConstant]?.includes(row?.group) && (
              <RowTotal
                value={row?.value?.reduce(
                  (acc, curr) => acc + (Number(curr.value) || 0),
                  0
                )}
              />
            )}
          </>
        );
      })}
      {!groupsFilled?.includes(data.group) &&
      GROUPS[groupsFilled?.length || 0] === data.group ? (
        <tr key="save-button">
          <td colSpan={9} className="text-end">
            <button name={data.group} className="btn btn-primary" type="submit">
              Save
            </button>
          </td>
        </tr>
      ) : !groupsFilled?.includes(data.group) ? (
        <tr>
          <td colSpan={5} className="text-end">
            <CustomTooltip
              content={
                "Please complete the previous form(s) to activate this button."
              }
              position="left"
              key={`${data.group}-disabled-key`}
            >
              <span
                style={{
                  width: "fit-content",
                  alignItems: "text-end",
                }}
              >
                <button
                  name={data.group}
                  className="btn btn-primary"
                  type="button"
                  disabled
                >
                  Save
                </button>
              </span>
            </CustomTooltip>
          </td>
        </tr>
      ) : null}
      {groupsFilled?.includes(data.group) && (
        <tr key="edit-button">
          <td colSpan={9} className="text-end">
            <button
              name={data.group}
              className="btn btn-primary"
              type={editForm === data.group ? "submit" : "button"}
              onClick={(e) => {
                if (editForm !== data.group) {
                  e.preventDefault();
                }
                setEditForm(data.group);
              }}
            >
              {editForm !== data.group ? "Edit" : "Save"}
            </button>
          </td>
        </tr>
      )}
    </tbody>
  );
}

function HeadRow({
  label,
  sr,
  attachment,
  setAttachment,
  group,
  showAttachment,
}) {
  const onChangeHandler = (event) => {
    const { files } = event.target;
    setAttachment?.((prev) => ({
      ...prev,
      [group]: files[0],
    }));
  };
  const onCancelHandler = () => {
    setAttachment((prev) => {
      delete prev?.[group];
      return { ...prev };
    });
  };
  return (
    <tr key={label}>
      <td key="sr-no">{sr}</td>
      <td key={label} colSpan={showAttachment ? 3 : 4}>
        {label}
      </td>
      {showAttachment && (
        <td>
          {attachment?.[group] ? (
            <span
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="fileUploader"
            >
              <span>{attachment?.[group]?.name.slice(0, 10) + "..."}</span>
              <span
                onClick={onCancelHandler}
                style={{
                  fontWeight: "700",
                  cursor: "pointer",
                  fontSize: "16px",
                  lineHeight: "20px",
                }}
                className="crossBtn"
              >
                X
              </span>
            </span>
          ) : (
            <span
              w-100
              style={{
                position: "relative",
              }}
            >
              <div
                className="chooseFile"
                style={{
                  position: "absolute",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Choose File
              </div>
              <input
                name="attachment"
                onChange={onChangeHandler}
                type="file"
                style={{
                  opacity: 0,
                  cursor: "pointer",
                  zIndex: 1,
                  top: 0,
                  width: "100%",
                  height: "100%",
                }}
                className="form-control w-100 h-100"
              />
            </span>
          )}
        </td>
      )}
    </tr>
  );
}

function Row({
  data,
  sr,
  setData,
  attachment,
  setAttachment,
  showAttachment,
  group,
}) {
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setData((prev) => {
      return updateField(prev, data.fieldId, {
        [name]: value,
      });
    });
  };

  const onFileUpload = (event) => {
    const { files } = event.target;
    setAttachment?.((prev) => ({
      ...prev,
      [data?.fieldId]: files[0],
    }));
  };
  const onCancelHandler = () => {
    setAttachment?.((prev) => {
      delete prev?.[data?.fieldId];
      return { ...prev };
    });
  };
  return (
    <tr key={`${data.fieldId}-row`}>
      <td>{sr}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <CustomTooltip position="top" content={data?.guidance}>
              <img src={info} alt="" />
            </CustomTooltip>
          )}
        </span>
      </td>
      <td>
        <input
          type={NUMERIC_FIELDS.includes(data.fieldName) ? "number" : "text"}
          name="value"
          onChange={onChangeHandler}
          value={data.value}
          className="rounded form-control w-100 numberCountInput"
          required
        />
      </td>
      <td>
        <input
          type="text"
          name="comment"
          onChange={onChangeHandler}
          value={data.comment}
          className="rounded form-control w-100 numberCountInput"
        />
      </td>
      {showAttachment && (
        <td>
          {attachment?.[data?.fieldId] ? (
            <span
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="fileUploader"
            >
              <span>
                {attachment?.[data?.fieldId]?.name.slice(0, 10) + "..."}
              </span>
              <span
                onClick={onCancelHandler}
                style={{
                  fontWeight: "700",
                  cursor: "pointer",
                  fontSize: "16px",
                  lineHeight: "20px",
                }}
                className="crossBtn"
              >
                X
              </span>
            </span>
          ) : (
            <span
              w-100
              style={{
                position: "relative",
              }}
            >
              <div
                className="chooseFile"
                style={{
                  position: "absolute",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Choose File
              </div>
              <input
                name="attachment"
                onChange={onFileUpload}
                type="file"
                style={{
                  opacity: 0,
                  cursor: "pointer",
                  zIndex: 1,
                  top: 0,
                  width: "100%",
                  height: "100%",
                }}
                className="form-control w-100 h-100"
              />
            </span>
          )}
        </td>
      )}
    </tr>
  );
}

function ShowRow({ data, sr, attachment, showAttachment }) {
  return (
    <tr key={`${data.fieldId}-row`}>
      <td>{sr}</td>
      <td>
        <span
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          {data?.fieldName}
          {data?.guidance && (
            <CustomTooltip position="top" content={data?.guidance}>
              <img src={info} alt="" />
            </CustomTooltip>
          )}
        </span>
      </td>
      <td>{data.value}</td>
      <td>{data.comment}</td>
      {showAttachment && (
        <td>
          {attachment?.[data?.group] ? (
            <span>{attachment?.[data?.group]?.name.slice(0, 10) + "..."}</span>
          ) : (
            ""
          )}
        </td>
      )}
    </tr>
  );
}

function ShowHeadRow({ label, sr, attachment, group, showAttachment }) {
  return (
    <tr key={label}>
      <td key="sr-no">{sr}</td>
      <td key={label} colSpan={showAttachment ? 3 : 4}>
        {label}
      </td>
      {showAttachment && (
        <td>
          {attachment?.[group] ? (
            <span
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="fileUploader"
            >
              <span>{attachment?.[group]?.name.slice(0, 10) + "..."}</span>
            </span>
          ) : (
            ""
          )}
        </td>
      )}
    </tr>
  );
}

function RowTotal({ value, className }) {
  return (
    <tr key={`${value}-row-total`} className={`subTotalTr ${className}`}>
      <td colSpan={2}>Total</td>
      <td>{value || 0}</td>
      <td colSpan={2}></td>
    </tr>
  );
}

export default observer(AddForm);

import { observer } from "mobx-react";
import useStore from "../../../../hooks/useStore";
import { useEffect, useState } from "react";
import {
  SMALL_GROUPS,
  GROUPS_WITH_TOTAL,
  NUMERIC_FIELDS,
  SOCIAL_GROUP_CONSTANTS,
} from "../../../../constants/constants";
import { Accordion } from "react-bootstrap";
import { convertToNestedFormat } from "../../../../utils/helpers";
import numToRoman from "../../../../helpers/numToRoman";
import { Link } from "react-router-dom";

const ShowForm = ({ groupConstant }) => {
  // global states
  const {
    social: { formData },
  } = useStore();
  // states
  const [expanded, setExpanded] = useState(0);
  const [data, setData] = useState([]);
  const [attachment, setAttachment] = useState({});

  useEffect(() => {
    const tempData = convertToNestedFormat(
      formData?.map((ele) => ({ ...ele })),
      SOCIAL_GROUP_CONSTANTS[groupConstant]
    );
    const atach = formData?.reduce(
      (acc, curr) => ({
        ...acc,
        ...(curr?.group?.includes("_")
          ? { [curr?.group?.slice(0, 3)]: curr.attachment }
          : { [curr?.group]: curr.attachment }),
        [curr?.fieldId]: curr.attachment,
      }),
      {}
    );
    setData(tempData);
    setAttachment(atach);
  }, [formData, groupConstant]);

  return (
    <div>
      <Accordion
        className="water-table-accordion"
        defaultActiveKey={0}
        activeKey={expanded}
        onSelect={(eventKey) => {
          setExpanded(eventKey);
        }}
      >
        {data?.map((accordion, index) => (
          <Accordion.Item
            key={`${accordion.group}-accordion-item`}
            eventKey={index}
          >
            <Accordion.Header>
              <tr style={{ display: "flex", gap: "4px" }}>
                <td>{index + 1}</td>
                <td colSpan={4}>
                  {SOCIAL_GROUP_CONSTANTS[groupConstant][accordion.group]}
                </td>
              </tr>
            </Accordion.Header>
            <Accordion.Body>
              <table className="table align-middle table-bordered table-nowrap mb-0">
                <thead>
                  <tr>
                    {["Sr. No.", "Title", "Value", "Comment", "Attachment"].map(
                      (head) => (
                        <th key={head}>{head}</th>
                      )
                    )}
                  </tr>
                </thead>
                <TableBody
                  data={accordion}
                  key={`${accordion.group}-accordion-body-table-body`}
                  attachment={attachment}
                  groupConstant={groupConstant}
                />
              </table>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

function TableBody({ data, attachment, groupConstant }) {
  return (
    <tbody>
      {data?.value?.map((row, index) => {
        if (!row.label) {
          return (
            <ShowRow
              data={row}
              sr={numToRoman(index + 1)}
              attachment={attachment}
              showAttachment
            />
          );
        }
        return (
          <>
            <HeadRow
              label={row.label}
              sr={numToRoman(index + 1)}
              showAttachment
              attachment={attachment}
              group={row.group}
            />
            {row?.value?.map((r, i) => {
              if (!r.label) {
                return <ShowRow data={r} sr={SMALL_GROUPS[i]} />;
              }
              return (
                <>
                  <HeadRow label={r.label} sr={SMALL_GROUPS[i]} />
                  {r?.value?.map((e) => (
                    <ShowRow data={e} sr="*" />
                  ))}
                  <RowTotal
                    value={r?.value?.reduce(
                      (acc, curr) => acc + (Number(curr.value) || 0),
                      0
                    )}
                  />
                </>
              );
            })}
            {GROUPS_WITH_TOTAL[groupConstant]?.includes(row?.group) && (
              <RowTotal
                value={row?.value?.reduce(
                  (acc, curr) => acc + (Number(curr.value) || 0),
                  0
                )}
              />
            )}
          </>
        );
      })}
    </tbody>
  );
}

function HeadRow({ label, sr, attachment, group, showAttachment }) {
  return (
    <tr key={label}>
      <td key="sr-no">{sr}</td>
      <td key={label} colSpan={showAttachment ? 3 : 4}>
        {label}
      </td>
      {showAttachment && (
        <td>
          {attachment?.[group] ? (
            <Link to={attachment?.[group]}>Download</Link>
          ) : (
            ""
          )}
        </td>
      )}
    </tr>
  );
}

function ShowRow({ data, sr, attachment, showAttachment }) {
  return (
    <tr key={`${data.fieldId}-row`}>
      <td>{sr}</td>
      <td>{data.fieldName}</td>
      <td>{data.value}</td>
      <td>{data.comment}</td>
      {showAttachment && (
        <td>
          {attachment?.[data.fieldId] ? (
            <Link to={attachment?.[data?.fieldId]}>Download</Link>
          ) : (
            ""
          )}
        </td>
      )}
    </tr>
  );
}

function RowTotal({ value, className }) {
  return (
    <tr key={`${value}-row-total`} className={`subTotalTr ${className}`}>
      <td colSpan={2}>Total</td>
      <td>{value || 0}</td>
      <td colSpan={2}></td>
    </tr>
  );
}

export default observer(ShowForm);
